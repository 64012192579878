import React, { useState } from 'react';
import { Col } from 'reactstrap';

const GlobalFilterComplaints = ({  onStatusChange,
    statusValue,
    approvalValue,
    onApprovalStatusChange,
    devices, parentCompanies
}) => {
    const [selectDate, setSelectDate] = useState();
    const dateChange = (date) => {
        setSelectDate(date)
    };

    // const [status, setStatus] = useState('');
    // const [approvalStatus, setApprovalStatus] = useState('');
  
    // const handleStatusChange = (event) => {
    //   setStatus(event.target.value);
    // };
  
    // const handleApprovalStatusChange = (event) => {
    //   setApprovalStatus(event.target.value);
    // };

    return (
        <React.Fragment>    
     
            <Col xxl={2} lg={6} >
        <select className="form-control select2 mb-3 mb-xxl-0" onChange={onStatusChange} value={statusValue} defaultValue="">
          <option disabled value={''}>Parent Company</option>
          <option value="All">All</option>
          {parentCompanies.map((company, index) => (
            <option key={index} value={company}>{company}</option>
          ))}
     
        </select>
      </Col>

      <Col xxl={2} lg={6} >
        <select className="form-control select2 mb-3 mb-xxl-0" onChange={onApprovalStatusChange} value={approvalValue} defaultValue="">
          <option disabled value={''}>Device Name</option>
          <option value="All">All</option>
          {devices.map((device, index) => (
            <option key={index} value={device}>{device}</option>
          ))}
    
        </select>
      </Col>
            {/* <Col xxl={2} lg={6}>
                <select className="form-control select2 mb-3 mb-xxl-0" 
                 onChange={onApprovalStatusChange}
                //   onChange={handleApprovalStatusChange}
                  value={approvalValue}
                  defaultValue=""
                >
                    <option disabled value={''}>Approval Status</option>
                    <option value="All">All</option>
                    <option value="Approved">Approved</option>
                    <option value="Pending">Pending</option>
                </select>
            </Col> */}
            {/* <Col xxl={2} lg={4}>
                <div className='mb-3 mb-xxl-0'>
                    <button type="button" className="btn btn-primary w-100">
                        <i className="mdi mdi-filter-outline align-middle me-1"></i> Advanced Filter</button>
                </div>
            </Col> */}
        </React.Fragment>
    )
};
export default GlobalFilterComplaints;

