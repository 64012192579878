// import axios from "axios";
import { del, get, post, put } from "./api_helper";
import * as url from "./api_url_helper";
import { authUser } from "./dataConfig";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem(authUser);
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// // Register Method
// const postRegister = data => {
//   return axios
//     .post(url.POST_REGISTER, data)
//     .then(response => {
//       if (response.status >= 200 || response.status <= 299) return response.data;
//       throw response.data;
//     })
//     .catch(err => {
//       let message;
//       if (err.response && err.response.status) {
//         switch (err.response.status) {
//           case 404:
//             message = "Sorry! the page you are looking for could not be found";
//             break;
//           case 500:
//             message =
//               "Sorry! something went wrong, please contact our support team";
//             break;
//           case 401:
//             message = "Invalid credentials";
//             break;
//           default:
//             message = err[1];
//             break;
//         }
//       }
//       throw message;
//     });
// };

// Login Method
const postLogin = data => post(url.POST_LOGIN, data);

// postForgetPwd
const postForgetPwd = data => post(url.POST_PASSWORD_FORGET, data);

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data);

const postProfile = data => post(url.POST_EDIT_PROFILE, data);



// // Register Method
// const postJwtRegister = (url, data) => {
//   return axios
//     .post(url, data)
//     .then(response => {
//       if (response.status >= 200 || response.status <= 299) return response.data;
//       throw response.data;
//     })
//     .catch(err => {
//       var message;
//       if (err.response && err.response.status) {
//         switch (err.response.status) {
//           case 404:
//             message = "Sorry! the page you are looking for could not be found";
//             break;
//           case 500:
//             message =
//               "Sorry! something went wrong, please contact our support team";
//             break;
//           case 401:
//             message = "Invalid credentials";
//             break;
//           default:
//             message = err[1];
//             break;
//         }
//       }
//       throw message;
//     });
// };

// Login Method
const postJwtLogin = data => post(url.POST_JWT_LOGIN, data);

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data);


// get company
export const getCompanyList = () => get(url.GET_COMPANY_LIST);

export const getUsersList = (user) => post(url.GET_USERS_LIST, user);


export const deleteCompany = company => post(url.DELETE_COMPANY,company);

// add new company
export const addNewCompany = company => post(url.ADD_NEW_COMPANY, company);

// update company
export const updateCompany = company => post(url.UPDATE_COMPANY, company);

// get phone code list
export const getPhonecodeList = () => get(url.GET_PHONECODE_LIST);

// get country list
export const getCountryList = () => get(url.GET_COUNTRY_LIST);

// get state list
export const getStateList = state => post(url.GET_STATE_LIST, state);


// get city list
export const getCityList = city => post(url.GET_CITY_LIST,city);

// get check email exisys
export const checkUserEmailExists = user => post(url.CHECK_USER_EMAIL_EXISTS,user);

export const validateLoginEmail = user => post(url.VALIDATE_LOGIN_EMAIL,user);


export const updateUserSignupStatus = user => post(url.UPDATE_USER_SIGNUP_STATUS,user)

export const getUserDetails = user => post(url.GET_USER_DETAILS,user)


export const getCompanyContactPerson = comp => post(url.GET_COMPANY_CONTACT_PERSON,comp)

export const approveCompany = comp => post(url.APPROVE_COMPANY,comp)


export const getUserProfile = user => post(url.GET_USER_PROFILE, user);


export const updateUserProfile = user => post(url.UPDATE_USER_PROFILE, user);


export const getDesignationList = () => get(url.GET_DESIGNATION_LIST);

export const getDepartmentList = () => get(url.GET_DEPARTMENT_LIST);

export const getCorporateList = () => get(url.GET_CORPORATE_DD_LIST);

export const getRecentQueryList = (user) => post(url.GET_RECENT_QUERY_LIST, user);

export const getSavedQueryList = (user) => post(url.GET_SAVED_QUERY_LIST,user);

export const getSeachableDataList = () => get(url.GET_SEARCH_FIELD_LIST);

export const getMedicalSplDataList = () => get(url.GET_MEDICAL_SPL_LIST);

export const getDeviceProbCodeDataList = () => get(url.GET_DEVICE_PROB_CODE_LIST);

export const getPatientProbCodeDataList = () => get(url.GET_PATIENT_PROB_CODE_LIST);

export const saveSmartQueryApi = query => post(url.SAVE_SMART_QUERY_API, query);

export const executeSmartQueryApi = query => post(url.EXECUTE_SMART_QUERY_API, query);

export const getUserCountDashboard = () => get(url.GET_USER_COUNT_DASHBOARD);

export const getMDRSubmitDashboard = () => get(url.GET_MDR_SUBMIT_DASHBOARD)

export const getMDRStatsDashboard = () => get(url.GET_MDR_STATS_DASHBOARD)

export const getMDRStatsDashboardYear = () => get(url.GET_MDR_STATS_DASHBOARD_YEAR)

export const getActiveCompanyUserList = () => get(url.GET_ACTIVE_COMPANY_LIST)

export const getActiveUserOrg = comp => post(url.GET_ACTIVE_USER_ORG, comp)

export const addNewUserData = user => post(url.ADD_NEW_USER, user);

export const deleteUser = user => post(url.DELETE_USER,user);

export const approveUser = user => post(url.APPROVE_USER,user)

export const updateUserData = user => post(url.UPDATE_USER,user)

export const sendUserInvite = user => post(url.SEND_USER_INTIVE,user)

export const getMdrListInitial = data => post(url.GET_MDR_INITIAL_LIST,data)

export const getMDRTopPatientsPublicData = () => get(url.GET_MDR_TOP_PATIENTS)

export const getMDRTopDevicesPublicData = () => get(url.GET_MDR_TOP_DEVICES)

export const getMDRYearDataStats = () => get(url.GET_MDR_YEAR_DATA_STATS)

export const getMDRMonthDataStats = () => get(url.GET_MDR_MONTH_DATA_STATS)

export const getMDREventDataStats  = () => get(url.GET_MDR_EVENT_COUNT_STATS)

export const getMDRYearlyDataStats  = () => get(url.GET_MDR_YEARLY_COUNT_STATS)

export const getMDRMedicalSpecDataStats  = () => get(url.GET_MDR_MEDICAL_SPEC_COUNT_STATS)

export const getMDRMonthlyEventDataStats  = () => get(url.GET_MDR_MONTHLY_EVENT_COUNT_STATS)

export const getMonthlyChartApi = data => post(url.GET_MONTHLY_CHART_SMART,data)

export const getFieldChartApi = data => post(url.GET_FIELD_CHART_SMART,data)

export  const globalSearchResultApi = query => post(url.GLOABL_SEARCH_RESULT_API, query);

export const getModuleList = (data) => post(url.GET_ALL_MODULE_LIST,data)

export const addModuleApiCall = data => post(url.ADD_NEW_MODULE_DT,data)

export const deleteModule = module => post(url.DELETE_MODULE,module);

export const updateModuleApiCall = module => post(url.UPDATE_MODULE,module);

export const approveModule = module => post(url.APPROVE_MODULE,module);

export const addNewTabData = tabData => post(url.ADD_TAB_DATA_DT,tabData);

export const  addNewQuestionData = qData => post(url.ADD_QUESTION_DATA_DT,qData);

export const addNewQuestionItemData = qData => post(url.ADD_QUESTION_ITEM_DATA_DT,qData);

export const onSaveDraftDTData = qData => post(url.ADD_SAVE_DRAFT_DATA_DT,qData);

export const onGetDraftDTData = qData => post(url.GET_DRAFT_DATA_DT,qData);

export const onGetActiveModule = () => get(url.GET_ACTIVE_MODULE_LIST)

export const getEventList = (data) => post(url.GET_EVENT_LIST,data)

export const addEventApiCall = eData => post(url.ADD_NEW_EVENT_DATA,eData);

export const approveEventDT = eData => post(url.APPROVE_EVENT_DT,eData);

export const deleteEventDT = eData => post(url.DELETE_EVENT_DT,eData);

export const updateEventApiCall = eData => post(url.UPDATE_EVENT_DT,eData);

export const getTabDataEventApiCall = eData => post(url.GET_TABDATA_EVENT,eData);

export const getQuestionDataEventApiCall = eData => post(url.GET_QUESTIONDATA_EVENT,eData);

export const getQuestionItemEventApiCall = eData => post(url.GET_QUESTIONITEM_EVENT,eData);

export const saveTabAnsDataApi = eData => post(url.SAVE_TAB_ANS_DATA,eData);

export const saveTabAnsItemDataApi = eData => post(url.SAVE_TAB_ANSITEM_DATA,eData);

export const removeTabAnsDataApi = eData => post(url.REMOVE_TAB_ANS_DATA,eData);

export const addModuleScoreApiCall = eData => post(url.ADD_MODULE_SCORE_API,eData);

export const removeModuleScoreDataApi = eData => post(url.REMOVE_MODULE_SCORE_API,eData);

export const getModuleScoreList = (data) => post(url.GET_ALL_MODULE_SCORE_LIST, data)

export const deleteModuleScore  = eData => post(url.DELETE_MODULE_SCORE,eData);

export const approveModuleScore = eData => post(url.APPROVE_MODULE_SCORE,eData);

export const getAnswerEventApiCall  = eData => post(url.GET_ANSWER_EVENT,eData); 

export const getAnswerItemEventApiCall = eData => post(url.GET_ANSWER_ITEM_EVENT,eData);

export const getModuleScoreByid = eData => post(url.GET_MODULE_SCORE_BYID,eData);

export const getGroupPermissionList = () => get(url.GET_GROUP_PERMISSION)

export const updateGroupPermission = eData => post(url.UPDATE_GROUP_PERMISSION,eData);

export const getComplaintUserList = eData => post(url.GET_COMPLAINTS_USER,eData);

export const getComplaintParentCompanyList = (text) => post(url.GET_COMPLAINTS_PARENT_COMPANY,text)

export const getComplaintDevicesList = (text) => post(url.GET_COMPLAINTS_DEVICES,text)

export const getParentCompanyQuestionList = (text) => post(url.GET_PARENT_COMPANY_QUESTION_DATA,text)

export const addEventAnswerData = answer => post(url.ADD_EVENT_ANSWER, answer)

export const addEventAnswerChecklistData = answer => post(url.ADD_EVENT_ANSWER_CHECKLIST, answer)

export const addEventAnswerItemChecklistData = answer => post(url.ADD_EVENT_ANSWER_ITEM, answer)

export const addEventComplaint = answer => post(url.ADD_EVENT_COMPLAINT, answer)

export const addUserSessionData = user => post(url.ADD_USER_SESSION, user)

export const getEventAnswersApi = data => post(url.GET_EVENT_ANSWERS_DATA, data)

export const sendComplaintMailerApi = data => post(url.SEND_COMPLAINT_MAILER, data)

export const sendComplaintMailerNonRegApi = data => post(url.SEND_COMPLAINT_MAILER_NON_REG, data)

export const sendComplaintMailerAckApi = data => post(url.SEND_COMPLAINT_MAILER_ACK, data)

export const updateEventComplaintApi  = data => post(url.UPDATE_EVENT_COMPLAINT, data)

export const sendComplaintWelcomeMailerApi = data => post(url.SEND_COMPLAINT_WELCOME_MAILER, data)

export {
  getLoggedInUser,
  isUserAuthenticated,
  // postRegister,
  postLogin,
  postProfile,
  postForgetPwd,
  // postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile
};
