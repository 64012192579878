import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Button, CardBody, Collapse,  Input,Col, InputGroup, Label } from 'reactstrap';
import Select from 'react-select';
import Flatpickr from "react-flatpickr";
import Dropzone from "react-dropzone";
import { addEventAnswer, addEventAnswerChecklist, saveMailerAnswers } from 'store/actions';
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';
import { image_upload } from 'helpers/dataConfig';

const Checklist = ({ questions, eventData, ansData, type  }) => {

  const dispatch = useDispatch();

    const [activeIndex, setActiveIndex] = useState(null);
    const [currentQuestion, setCurrentQuestion] = useState(questions);
    const [commentsVisible, setCommentsVisible] = useState(false);
    const [currentChoice, setCurrentChoice] = useState(null);
    const [viewQuestionData, setViewQuestionData] = useState([]);


    const [answerText, setAnswerText] = useState('');
    const [commentsText, setCommentsText] = useState('');
    const [answerQuestion, setAnswerQuestion] = useState('');
    const [finalAnswer, setFinalAnswer] = useState(null);

    const toggle = (index, choice) => {
        // setActiveIndex(activeIndex === index ? null : index);
        // setCurrentQuestion(questions[index])
        // console.log(index == -1)
      
        if(index == -1){
          setCurrentQuestion(null)
          setCurrentChoice(null)
          setCommentsVisible(false)
        }else{
          setCurrentChoice(null)
          setCommentsVisible(false)
          setCurrentQuestion(questions[index])
          // currentChoice(choice)
          // commentsVisible(true)

          // showComments(questions[index],choice)
      
      }
    };

    const showComments = (ques, choice) =>{
      if(choice.display_comments){
        setCurrentChoice(choice)
        setCommentsVisible(true)
      }else{
        setCurrentChoice(null)
        setCommentsVisible(false)
        const nextQuestionIndex = questions.findIndex(q => q.question_id === ques.next_question);
        toggle(nextQuestionIndex, choice);
      }

    }

    useEffect(()=>{
      setCurrentQuestion(questions)
    setViewQuestionData(questions)

    },[questions])
    

    // Function to render different question types
    const renderQuestionType = (question) => {
        switch (question.content_item_type) {
          case "Branching":
            return <div>
              {question.choice_data.map((choice) => (
                                <div key={choice.choice_id} className='mb-4'>
                                    <button style={{ width: '100%' }} className={
                                      (answerText == choice.choice_text && answerQuestion == question) ||
                                      question.answer_text == choice.choice_text ? 'btn btn-primary' : 'btn btn-outline-primary'} onClick={() => {
                                        const nextQuestionIndex = questions.findIndex(q => q.question_id === choice.next_question);
                                        // toggle(nextQuestionIndex, choice);
                                        // showComments(question, choice )
                                        // updateQuestionAnswer(question, choice.choice_text); 
                                        setAnswerText(choice.choice_text) 
                                        setAnswerQuestion(question)  
                                        setCurrentChoice(choice)
                                      
                                        if(!choice.display_comments){
                                          updateQuestionAnswer(question, choice, choice.choice_text)
                                        }
                                    }}>
                                        {choice.choice_text}
                                        
                                    </button>

                                   
                                </div>
                            ))}
            </div>
            case "Form":
                return <div style={{padding : 20, display : 'grid'}}>
                      {question.choice_data.map((choice, ch_index) => (
                        <div key={ch_index}>
                            {renderFormType(choice, question)}
                      
                        </div>
                      
                      ))}
                      
                    </div>; // Simplified
            case "Choices Multiple":
               return <div style={{padding : 20}}>
                    {MultipleTextView(question)}
                   
                        
                    </div>
            case "Content Piece":
                return <div dangerouslySetInnerHTML={{__html: question.question_sub_text}} />;
            case "Image Attachment":
                return <div>
                   {question.choice_data.map((choice,ind) => (
                <Dropzone key={ind}
                onDrop={acceptedFiles => {
                  // handleAcceptedFiles(acceptedFiles)
                 //  console.log(acceptedFiles)
                  const data = new FormData();
                  data.append('file', acceptedFiles[0]);
                
                  try {
                    const response = axios.post(image_upload, data, {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                    });
                 //    console.log('Response:', response.data);
                    updateQuestionAnswer(ques, choice, response.fileurl)
                  } catch (error) {
                    console.error('Error uploading file:', error);
                  }
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div
                      className="dz-message needsclick mt-2"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                      </div>
                      <h4>Drop images here or click to upload.</h4>
                    </div>
                  </div>
                )}
              </Dropzone>
                      ))}
                    </div>
            case "Choices":
                return  <div style={{padding : 20}}>
                    {SingleSelectTextView(question)}
                   
                    </div>
            default:
                return null;
        }
    };


     // Function to render different question types
     const renderAnswerType = (question) => {
      // console.log(question)
      switch (question.content_item_type) {
        case "Branching":
          return  <div> {question.answer_text}</div>
        case "Form":
          return <div style={{ padding: 20, display: 'grid' }}>
            {question.choice_data.map((choice, ch_index) => (
              <div key={ch_index}>
                <h6>{choice.choice_text}</h6>
                <p>{choice.ans_item_text}</p>
              </div>
  
            ))}
            
          </div>; // Simplified
        case "Choices Multiple":
          return <div style={{ padding: 20 }}>
            
             <div> {question.answer_text}</div>
  
           
          </div>
        case "Content Piece":
          return <div dangerouslySetInnerHTML={{ __html: question.question_sub_text }} />;
        // case "Image Attachment":
        case "Image Attachment":
          return <div>
            {/* {question.choice_data.map((choice, ind) => ( */}
           
              <div >
              <img src={question.answer_text} />
              </div>
       
          </div>
        case "Choices":
          return <div style={{ padding: 20 }}>
            {/* {SingleSelectTextView(question)} */}
              {question.answer_text}
          </div>
        default:
          return null;
      }
    };


        // Function to update the answer for a specific question
// const updateQuestionAnswer = (question, choice, answerText) => {
//   // console.log(question)
//   const updatedQuestions = currentQuestion.map((q) => {
//     if (q.question_id == question.question_id) {
//       const updatedChoices = q.choice_data.map((c) => {
//         if (c.choice_id == choice.choice_id) {
//           return { ...c, ans_item_text: answerText};
//         }
//         return c;
//       });
//       return { ...q, choice_data: updatedChoices };
//     }
//     return q;
//   });
//   // console.log(updatedQuestions)

//   setCurrentQuestion(updatedQuestions)
// }


    // Function to update the answer for a specific question
    const updateQuestionAnswer = (question, choice, answer) => {
      let updatedIndex = 0
      const nextQuestionIndex = questions.findIndex(q => q.question_id === choice.next_question);
      const updatedQuestions = currentQuestion.map((q,indx) => {
          if (q.question_id === question.question_id) {
            updatedIndex = indx
              return { ...q, answer_text: answer, ans_comments : commentsText }; // Assuming you want to store the answer directly in the question object
          }
          return q;
      });
    
      dispatch(addEventAnswer({...updatedQuestions[updatedIndex], eventid : eventData.EVENT_ID}))
      setAnswerText('')
      setCommentsText('')
      setAnswerQuestion('')
      setCurrentChoice('')
    
      // setCurrentQuestion(nextQuestionIndex);
      if(nextQuestionIndex == -1){

        let quesMainData = [...viewQuestionData]      
        quesMainData[quesMainData.findIndex(val => val.question_id == question.question_id)] = updatedQuestions[updatedIndex]
        setViewQuestionData(quesMainData)
        dispatch(saveMailerAnswers(quesMainData))

        setFinalAnswer(currentQuestion)
        setCurrentQuestion(null)
      }else{
      
        let quesMainData = [...viewQuestionData]      
        quesMainData[quesMainData.findIndex(val => val.question_id == question.question_id)] = updatedQuestions[updatedIndex]
        setViewQuestionData(quesMainData)
        dispatch(saveMailerAnswers(quesMainData))
        // setCurrentQuestion([...updatedQuestions, questionsRef[nextQuestionIndex]])
      }
    
      
     
    };
    

        // Function to update the answer for a specific question
 const updateQuestionComments = (question, choice, commentsText) => {
          const updatedQuestions = currentQuestion.map((q) => {
            if (q.question_id === question.question_id) {
              const updatedChoices = q.choice_data.map((c) => {
                if (c.choice_id === choice.choice_id) {
                  return { ...c, ans_item_comments: commentsText};
                }
                return c;
              });
              return { ...q, choice_data: updatedChoices };
            }
            return q;
          });
          setCurrentQuestion([...currentQuestion, updatedQuestions])
          let quesMainData = [...viewQuestionData]      
          quesMainData = updatedQuestions
          setViewQuestionData(quesMainData)
          dispatch(saveMailerAnswers(quesMainData))
        }

     // Function to render different form types
     const renderFormType = (choice, question) => {
        switch (choice.field_type) {
            case "Date":
                return <div className='row'>
                  <div className={choice.display_comments ? 'col-lg-12' : 'col-lg-12'}><Label>{choice.choice_text}</Label>{DateTextView(question, choice)}  
                  <div className='col-lg-12'>
              <div className="input-wrapper mb-5" style={{display : choice.display_comments ? '' : 'none'}}>
      <Label className={choice.comments_required ? "form-label required" : "form-label"} style={{marginBottom :0}}>Add Comments</Label>
      <Input
        name="key"
        type="textarea"
        rows={5}
        value={choice.ans_item_comments}
        onChange={(text)=>{
          updateQuestionComments(question, choice, text.target.value)
        }}
        placeholder="" />
      </div>
            </div>
                   <hr
                style={{
                    color: '#dcdcdc',
                    backgroundColor: '#dcdcdc',
                    height: 1
                }}
            /></div>
          
            </div>;
            case "Picklist":
                return <div className='row'><div className='col-lg-12'><Label>{choice.choice_text}</Label>{DropdownTextView(question, choice)}
                
                <div className='col-lg-12'>
              <div className="input-wrapper mb-5" style={{display : choice.display_comments ? '' : 'none'}}>
      <Label className={choice.comments_required ? "form-label required" : "form-label"} style={{marginBottom :0}}>Add Comments</Label>
      <Input
        name="key"
        type="textarea"
        rows={5}
        onChange={(text)=>{
          updateQuestionComments(question, choice, text.target.value)
        }}
        placeholder="" />
      </div>
            </div>
                <hr
                style={{
                    color: '#dcdcdc',
                    backgroundColor: '#dcdcdc',
                    height: 1
                }}
            />
                </div>
            </div>;
            case "Text":
                return <div className='row'><div className={choice.display_comments ? 'col-lg-12' : 'col-lg-12'}><Label className="form-label required">{choice.choice_text}</Label>{ShortTextView(question, choice)}
               </div>
               <div className='col-lg-12'>
              <div className="input-wrapper mb-5" style={{display : choice.display_comments ? '' : 'none'}}>
      <Label className={choice.comments_required ? "form-label required" : "form-label"}>Add Comments</Label>
      <Input
        name="key"
        type="textarea"
        rows={3}
        placeholder="" />
      </div>
            </div>
                <hr
                style={{
                    color: '#dcdcdc',
                    backgroundColor: '#dcdcdc',
                    height: 1
                }}
            />
                
             
            </div>;
            case "Long Text":
                return <div className='row'><div className='col-lg-12'><Label>{choice.choice_text}</Label>{LongTextView(question, choice)}
               
               <div className='col-lg-12'>
              <div className="input-wrapper mb-5" style={{display : choice.display_comments ? '' : 'none'}}>
      <Label className={choice.comments_required ? "form-label required" : "form-label"} style={{marginBottom :0}}>Add Comments</Label>
      <Input
        name="key"
        type="textarea"
        rows={5}
        onChange={(text)=>{
          updateQuestionComments(question, choice, text.target.value)
        }}
        placeholder="" />
      </div>
            </div>
                <hr
                style={{
                    color: '#dcdcdc',
                    backgroundColor: '#dcdcdc',
                    height: 1
                }}
            />
                </div>
            </div>;
            case "Number":
                return <div className='row'><div className='col-lg-6'><Label>{choice.choice_text}</Label>{NumberTextView(question, choice)}
               
               <div className='col-lg-12'>
              <div className="input-wrapper mb-5" style={{display : choice.display_comments ? '' : 'none'}}>
      <Label className={choice.comments_required ? "form-label required" : "form-label"} style={{marginBottom :0}}>Add Comments</Label>
      <Input
        name="key"
        type="textarea"
        rows={5}
        onChange={(text)=>{
          updateQuestionComments(question, choice, text.target.value)
        }}
        placeholder="" />
      </div>
            </div>
                <hr
                style={{
                    color: '#dcdcdc',
                    backgroundColor: '#dcdcdc',
                    height: 1
                }}
            />
                </div>
            </div>;
            default:
                return null;
        }
    };



  const ShortTextView = (question,choice) =>{
    return (
      <Col lg='12'>
      <div className=" mb-3">
      <Input
    //   disabled 
      name="key"
      type="textarea"
      rows={3}
      placeholder=""
      value={choice.ans_item_text}
      onChange={(e) => updateQuestionAnswer(question,choice, e.target.value)
                                  }
  />
   </div>
   </Col>
    )
  }


  const LongTextView = (question,choice) =>{
    return (
      <Col lg='12'>
      <div className="mb-3">
      <Input
    //   disabled
      name="key"
      type="textarea"
      rows={5}
      placeholder=""
      value={choice.ans_item_text}
      onChange={(e) => updateQuestionAnswer(question,choice, e.target.value)
                                  }
  />
   </div>
   </Col>
    )
  }


  const NumberTextView = (question,choice) =>{
    return (
      <Col lg='12'>
      <div className="mb-3">
      <InputGroup>
                        <div
                          className="input-group-append"
                          onClick={() => {
                            if(counter > 0){
                            setCounter(counter - 1);
                            updateQuestionAnswer(question,choice, counter - 1)
                            }
                          }}
                        >
                          <Button type="button" color="primary">
                            <i className="mdi mdi-minus" />
                          </Button>
                        </div>
                        <Input
                        style={{
                          borderRight: 0,
                          marginRight:-5,
                          borderLeft: 0,
                          marginLeft: -5,
                          background : 'transparent',
                          textAlign : 'center'
                        }}
                      
                          type="number"
                          // className="form-control"
                          value={counter}
                          onChange={(e)=>{
                            // if(e.target.value == ''){
                            //   setCounter(0)
                            // }
                               setCounter(parseInt(e.target.value))
                               updateQuestionAnswer(question,choice, parseInt(e.target.value))
                            // }
                          }}
                          // placeholder="number"
                          // readOnly
                          
                        />
                        <div
                          className="input-group-append"
                          onClick={() => {
                           
                              setCounter(counter + 1);
                              updateQuestionAnswer(question,choice, counter + 1)
                          
                          }}
                        >
                          <Button type="button" color="primary">
                            <i className="mdi mdi-plus" />
                          </Button>
                        </div>
                      </InputGroup>
   </div>
   </Col>
    )
  }


  const DateTextView = (question, choice) =>{
    return (
      <Col lg='12'>
      <div className="mb-3">
      <Flatpickr
              
              className="form-control d-block"
              placeholder="Pick a date"
              options={{
                altInput: true,
                dateFormat: "d-m-y"
              }}
              value={Date.parse(choice.ans_item_text)}
              onChange={(e) => updateQuestionAnswer(question,choice, moment(new Date(e)).format('MM/DD/YYYY'))
              }
            />
   </div>
   </Col>
    )
  }


  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'white',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'white' : 'white', // Change background color when selected
      color: state.isSelected ? 'black' : 'black', // Change text color when selected
    }),
  };

  const DropdownTextView =  (question, choice) =>{
    
    return (
      <Col lg='12'>
      <div className="mb-3">
     
 
      <Select
                                  name={''}
                                  isClearable
                                  isSearchable
                                  options={choice.picklist_values.split(',').map((option, index) => ({
                                    label: option,
                                    value:option, // or any unique identifier for the value
                                  }))}
                                  value={choice.ans_item_text}
                                  onChange={(e) => updateQuestionAnswer(question,choice, e.value)
                                  }
                                  styles={customStyles}
                             
                                />

   </div>
   </Col>
    )
  }


  const MultipleTextView =  (ques) =>{
    return (
        <Col lg='12'>
        <div className="mb-3" style={{paddingLeft : 15, paddingRight : 15}}>
       
       {ques.choice_data.map((choice, index)=>(
         <div className="form-check form-checkbox-outline form-check-primary mb-4 col-lg-12" key={index}>
         <input
                 style={{color: '#232D42'}}
           type="checkbox"
           className="form-check-input"
           id="customCheck-outlinecolor1"
           // checked={customOutlinePrimary}
           // onChange={() => {
           //   setcustomOutlinePrimary(!customOutlinePrimary)
           // }}
         />
         <label
         style={{color: '#232D42'}}
           className="form-check-label"
           htmlFor="customCheck-outlinecolor1"
         >
          {choice.choice_text}
         </label>
       </div>
       ))}
       
  
     </div>
     </Col>
      )
    }

  const SingleSelectTextView =  (ques) =>{
    return (
      <Col lg='12'>
      <div className="mb-3" style={{paddingLeft : 15, paddingRight : 15}}>
     
     {ques.choice_data.map((choice, index)=>(
       <div className="form-check form-checkbox-outline form-check-primary mb-4 col-lg-12" key={index}>
       <input
               style={{color: '#232D42'}}
         type="radio"
         className="form-check-input"
         id="customCheck-outlinecolor1"
         // checked={customOutlinePrimary}
         // onChange={() => {
         //   setcustomOutlinePrimary(!customOutlinePrimary)
         // }}
         onChange={()=>{
          const nextQuestionIndex = questions.findIndex(q => q.question_id === ques.next_question);
          // toggle(nextQuestionIndex, choice);
          showComments(ques, choice )
        }}
       />
       <label
       style={{color: '#232D42'}}
         className="form-check-label"
         htmlFor="customCheck-outlinecolor1"
       >
        {choice.choice_text}
       </label>
     </div>
     ))}
     

   </div>
   </Col>
    )
  }


    return (
        <div>
            {currentQuestion != null && currentQuestion.length > 0 && type == 'add' ?
            <div>
            {currentQuestion.map((question, index) => (
               <Card key={question.question_id}>
                    <CardHeader className='cardheader002 card-header'>
                        <h5 className='question-02'>
                            {question.question_text}
                        </h5>
                        <h6 style={{ marginLeft: 20 }}><div dangerouslySetInnerHTML={{__html: question.question_sub_text}} /></h6>
                    </CardHeader>

           
                    <Collapse isOpen={true}>
                        <CardBody>
                        {renderQuestionType(question)}

                    {/* {currentChoice &&  <div style={{display : 'grid'}}><div className="input-wrapper mb-5" style={{display : commentsVisible && currentChoice.display_comments ? '' : 'none'}}>
      <Label className={currentChoice.comments_required ? "form-label required" : "form-label"} style={{marginBottom :0}}>Add Comments</Label>
      <Input
        name="key"
        type="textarea"
        rows={5}
        placeholder="" />
      </div>
      <button style={{ margin : 'auto'}} className='btn btn-primary' onClick={() => {
                                        const nextQuestionIndex = questions.findIndex(q => q.question_id === currentChoice.next_question);
                                        toggle(nextQuestionIndex);
                                       
                                    }}>
                                       <i className="bx bx-save"></i> Save
                                        
                                    </button>
</div>
} */}
                            {/* {question.choice_data.map((choice) => (
                                <div key={choice.choice_id} className='mb-4'>
                                    <button style={{ width: '100%' }} className='btn btn-outline-primary' onClick={() => {
                                        const nextQuestionIndex = questions.findIndex(q => q.question_id === choice.next_question);
                                        toggle(nextQuestionIndex);
                                    }}>
                                        {choice.choice_text}
                                        
                                    </button>
                                </div>
                            ))} */}
                            {/* Render sub-questions that have this question as their parent */}
                            {/* {questions.filter(q => q.parent_content_item === question.question_id).map((subQuestion, index2) => (
                                <div key={subQuestion.question_id} style={{ marginTop: '20px' }}>
                                      
                                      <Card key={question.question_id}>
                                        <CardHeader style={{ cursor: 'pointer', padding: '15px 10px' }} onClick={() => toggle(index)}>
                                        <h6 className="mb-2">
                                           {subQuestion.question_text}
                                        </h6>
                                        <h6 style={{ marginLeft: 20 }}>{question.question_sub_text}</h6>
                                    </CardHeader>
                                  <Collapse isOpen={activeIndex == index} >
                                    {renderQuestionType(subQuestion)}
                                    </Collapse>
                                    </Card>
                                </div>
                            
                            ))} */}
                        </CardBody>
                    </Collapse>
                </Card>
                           
              //   {questions.length >0 && currentQuestion == null ? 
              //         <div>
              //         <h5>Thank you!</h5>
              //       </div>   
              // : ''}
            ))}

<button style={{ margin : 'auto'}} className='btn saveButton' onClick={() => {
  // console.log(currentQuestion)
  dispatch(addEventAnswerChecklist({currentQuestion, eventid : eventData.EVENT_ID}))

  setCurrentQuestion(null)
                                        // const nextQuestionIndex = questions.findIndex(q => q.question_id === question.next_question);
                                        // toggle(nextQuestionIndex);
                                        // if(nextQuestionIndex == -1){
                                          // setCurrentQuestion(null)
                                        // }else{
                                          // setCurrentQuestion(questions[index])
                                          
                                        // }
                                    }}>
                                       <i className="bx bx-save"></i> Save
                                        
                                    </button>
</div>
                                   :  <div>
    
        <div>

          {/* <h5>Thank you!</h5> */}
          {/* <button className='btn btn-primary'
          onClick={(e)=>{
            e.preventDefault()
          }}
          >
            View Details
            </button> */}

{viewQuestionData.length > 0 && type == 'add' && 
viewQuestionData.map((ques,index)=>(
  <div key={index} style={{marginBottom : 30,display : ques.answer_text == '' ? 'none' : ''}}>
  <h6 className='question-02'>
 Question {ques.sort_no}. {ques.question_text}
</h6>
<div style={{display: 'flex', alignItems: 'center'}}>
  <div style={{fontWeight : 450, marginRight : 10}}>Ans.</div> {renderAnswerType(ques)}
</div>
</div>
))
}

{ansData.length > 0 && type == 'alter' &&
ansData.map((ques,index)=>(
  <div key={index} style={{marginBottom : 30,display : ques.answer_text == '' ? 'none' : ''}}>
  <h6 className='question-02'>
 Question {ques.sort_no}. {ques.question_text}
</h6>
<div style={{display: 'flex', alignItems: 'center'}}>
  <div style={{fontWeight : 450, marginRight : 10}}>Ans.</div> {renderAnswerType(ques)}
</div>
</div>
))
}
        </div>
        
      </div>}
        </div>
    );
};

export default Checklist;
