export const GET_COMPLAINT_USER = "GET_COMPLAINT_USER"
export const GET_COMPLAINT_USER_SUCCESS = "GET_COMPLAINT_USER_SUCCESS"
export const GET_COMPLAINT_USER_FAIL = "GET_COMPLAINT_USER_FAIL"

export const GET_COMPLAINT_PARENT_COMPANY = "GET_COMPLAINT_PARENT_COMPANY"
export const GET_COMPLAINT_PARENT_COMPANY_SUCCESS = "GET_COMPLAINT_PARENT_COMPANY_SUCCESS"
export const GET_COMPLAINT_PARENT_COMPANY_FAIL = "GET_COMPLAINT_PARENT_COMPANY_FAIL"

export const GET_COMPLAINT_DEVICES = "GET_COMPLAINT_DEVICES"
export const GET_COMPLAINT_DEVICES_SUCCESS = "GET_COMPLAINT_DEVICES_SUCCESS"
export const GET_COMPLAINT_DEVICES_FAIL = "GET_COMPLAINT_DEVICES_FAIL"

export const GET_PARENT_COMPANY_QUESTION_DATA = "GET_PARENT_COMPANY_QUESTION_DATA"
export const GET_PARENT_COMPANY_QUESTION_DATA_SUCCESS = "GET_PARENT_COMPANY_QUESTION_DATA_SUCCESS"
export const GET_PARENT_COMPANY_QUESTION_DATA_FAIL = "GET_PARENT_COMPANY_QUESTION_DATA_FAIL"

export const SET_COMPLAINT_MODULE_DATA = "SET_COMPLAINT_MODULE_DATA"

export const ADD_EVENT_ANSWER = "ADD_EVENT_ANSWER"
export const ADD_EVENT_ANSWER_SUCCESS = "ADD_EVENT_ANSWER_SUCCESS"
export const ADD_EVENT_ANSWER_FAIL = "ADD_EVENT_ANSWER_FAIL"

export const ADD_EVENT_ANSWER_CHECKLIST = "ADD_EVENT_ANSWER_CHECKLIST"
export const ADD_EVENT_ANSWER_CHECKLIST_SUCCESS = "ADD_EVENT_ANSWER_CHECKLIST_SUCCESS"
export const ADD_EVENT_ANSWER_CHECKLIST_FAIL = "ADD_EVENT_ANSWER_CHECKLIST_FAIL"

export const ADD_EVENT_ANSWER_REGULAR = "ADD_EVENT_ANSWER_REGULAR"
export const ADD_EVENT_ANSWER_REGULAR_SUCCESS = "ADD_EVENT_ANSWER_REGULAR_SUCCESS"
export const ADD_EVENT_ANSWER_REGULAR_FAIL = "ADD_EVENT_ANSWER_REGULAR_FAIL"

export const ADD_EVENT_COMPLAINT = "ADD_EVENT_COMPLAINT"
export const ADD_EVENT_COMPLAINT_SUCCESS = "ADD_EVENT_COMPLAINT_SUCCESS"
export const ADD_EVENT_COMPLAINT_FAIL = "ADD_EVENT_COMPLAINT_FAIL"

export const GET_EVENT_ANSWER = "GET_EVENT_ANSWER"
export const GET_EVENT_ANSWER_SUCCESS = "GET_EVENT_ANSWER_SUCCESS"
export const GET_EVENT_ANSWER_FAIL = "GET_EVENT_ANSWER_FAIL"

export const SAVE_MAILER_ANSWERS = "SAVE_MAILER_ANSWERS"

export const UPDATE_EVENT_COMPLAINT = "UPDATE_EVENT_COMPLAINT"
export const UPDATE_EVENT_COMPLAINT_SUCCESS = "UPDATE_EVENT_COMPLAINT_SUCCESS"
export const UPDATE_EVENT_COMPLAINT_FAIL = "UPDATE_EVENT_COMPLAINT_FAIL"


export const SET_LOADING_INDICATOR_COMPLAINT = "SET_LOADING_INDICATOR_COMPLAINT"