import { ADD_EVENT_ANSWER, ADD_EVENT_ANSWER_CHECKLIST, ADD_EVENT_ANSWER_CHECKLIST_FAIL, ADD_EVENT_ANSWER_CHECKLIST_SUCCESS, ADD_EVENT_ANSWER_FAIL, ADD_EVENT_ANSWER_REGULAR, ADD_EVENT_ANSWER_REGULAR_FAIL, ADD_EVENT_ANSWER_REGULAR_SUCCESS, ADD_EVENT_ANSWER_SUCCESS, ADD_EVENT_COMPLAINT, ADD_EVENT_COMPLAINT_FAIL, ADD_EVENT_COMPLAINT_SUCCESS, GET_COMPLAINT_DEVICES, GET_COMPLAINT_DEVICES_FAIL, GET_COMPLAINT_DEVICES_SUCCESS, GET_COMPLAINT_PARENT_COMPANY, GET_COMPLAINT_PARENT_COMPANY_FAIL, GET_COMPLAINT_PARENT_COMPANY_SUCCESS, GET_COMPLAINT_USER, GET_COMPLAINT_USER_FAIL, GET_COMPLAINT_USER_SUCCESS, GET_EVENT_ANSWER, GET_EVENT_ANSWER_FAIL, GET_EVENT_ANSWER_SUCCESS, GET_PARENT_COMPANY_QUESTION_DATA, GET_PARENT_COMPANY_QUESTION_DATA_FAIL, GET_PARENT_COMPANY_QUESTION_DATA_SUCCESS, SAVE_MAILER_ANSWERS, SET_COMPLAINT_MODULE_DATA, SET_LOADING_INDICATOR_COMPLAINT, UPDATE_EVENT_COMPLAINT, UPDATE_EVENT_COMPLAINT_FAIL, UPDATE_EVENT_COMPLAINT_SUCCESS } from "./actionTypes"

export const getComplaintUser = () => ({
  type: GET_COMPLAINT_USER
})

export const getComplaintUserSuccess = complaints => ({
  type: GET_COMPLAINT_USER_SUCCESS,
  payload: complaints,
})


export const getComplaintUserFail = error => ({
  type: GET_COMPLAINT_USER_FAIL,
  payload: error,
})


export const getComplaintParentCompany = search_text => ({
  type: GET_COMPLAINT_PARENT_COMPANY,
  payload : search_text
})

export const getComplaintParentCompanySuccess = parent_companys => ({
  type: GET_COMPLAINT_PARENT_COMPANY_SUCCESS,
  payload: parent_companys,
})


export const getComplaintParentCompanyFail = error => ({
  type: GET_COMPLAINT_PARENT_COMPANY_FAIL,
  payload: error,
})


export const getComplaintDevices = (search_text) => ({
  type: GET_COMPLAINT_DEVICES,
  payload : search_text
})

export const getComplaintDevicesSuccess = devices => ({
  type: GET_COMPLAINT_DEVICES_SUCCESS,
  payload: devices,
})


export const getComplaintDevicesFail = error => ({
  type: GET_COMPLAINT_DEVICES_FAIL,
  payload: error,
})



export const getParentCompanyQuestionData = (data) => ({
  type: GET_PARENT_COMPANY_QUESTION_DATA,
  payload : data
})

export const getParentCompanyQuestionDataSuccess = question_data => ({
  type: GET_PARENT_COMPANY_QUESTION_DATA_SUCCESS,
  payload: question_data,
})


export const getParentCompanyQuestionDataFail = error => ({
  type: GET_PARENT_COMPANY_QUESTION_DATA_FAIL,
  payload: error,
})

export const setComplaintModuleData = module => ({
  type: SET_COMPLAINT_MODULE_DATA,
  payload: module,
})



export const addEventAnswer = (answer) => ({
  type: ADD_EVENT_ANSWER,
  payload: {answer},
})

export const addEventAnswerSuccess = answer => ({
  type: ADD_EVENT_ANSWER_SUCCESS,
  payload: answer,
})

export const addEventAnswerFail = error => ({
  type: ADD_EVENT_ANSWER_FAIL,
  payload: error,
})


export const addEventAnswerChecklist = (answer) => ({
  type: ADD_EVENT_ANSWER_CHECKLIST,
  payload: {answer},
})

export const addEventAnswerChecklistSuccess = answer => ({
  type: ADD_EVENT_ANSWER_CHECKLIST_SUCCESS,
  payload: answer,
})

export const addEventAnswerChecklistFail = error => ({
  type: ADD_EVENT_ANSWER_CHECKLIST_FAIL,
  payload: error,
})

export const addEventAnswerRegular = (answer) => ({
  type: ADD_EVENT_ANSWER_REGULAR,
  payload: {answer},
})

export const addEventAnswerRegularSuccess = answer => ({
  type: ADD_EVENT_ANSWER_REGULAR_SUCCESS,
  payload: answer,
})

export const addEventAnswerRegularFail = error => ({
  type: ADD_EVENT_ANSWER_REGULAR_FAIL,
  payload: error,
})



export const addEventComplaint =  (data, history, ans_dt, comp_reg) => ({
  type: ADD_EVENT_COMPLAINT,
  payload: {data, history, ans_dt, comp_reg},
})

export const addEventComplaintSuccess = data => ({
  type: ADD_EVENT_COMPLAINT_SUCCESS,
  payload: data,
})

export const addEventComplaintFail = error => ({
  type: ADD_EVENT_COMPLAINT_FAIL,
  payload: error,
})


export const getEventAnswer = (data) => ({
  type: GET_EVENT_ANSWER,
  payload: data,
})

export const getEventAnswerSuccess = answers => ({
  type: GET_EVENT_ANSWER_SUCCESS,
  payload: answers,
})

export const getEventAnswerFail = error => ({
  type: GET_EVENT_ANSWER_FAIL,
  payload: error,
})

export const saveMailerAnswers = (data) => ({
  type: SAVE_MAILER_ANSWERS,
  payload: data,
})


export const updateEventComplaint = (data, history, ans_dt,comp_reg,comp_no) => ({
  type: UPDATE_EVENT_COMPLAINT,
  payload: {data, history, ans_dt,comp_reg,comp_no},
})

export const updateEventComplaintSuccess = answers => ({
  type: UPDATE_EVENT_COMPLAINT_SUCCESS,
  payload: answers,
})

export const updateEventComplaintFail = error => ({
  type: UPDATE_EVENT_COMPLAINT_FAIL,
  payload: error,
})


export const setLoadingIndicatorComplaint = (loading) => {
  // console.log(loading)
  return {
    type: SET_LOADING_INDICATOR_COMPLAINT,
    payload: loading,
  };
};