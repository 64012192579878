import React, { useEffect, useMemo, useState, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {
    getCompanyList as onGetCompanyList,
    addNewCompany as onAddNewCompany,
    updateCompany as onUpdateCompany,
    deleteCompany as onDeleteCompany,
    getComplaintUser,
} from "store/actions";

import {
    No, CompanyName, CompanyEmail, PhoneNo, Location, ApprovalStatus, UpdateOn, Status
}
    from "./ComplaintListCol";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button
} from "reactstrap";
import Loader from "components/Common/Loader";


function ComplaintList() {

    //meta title
    document.title = "Complaint List | Smarteeva";


    const tableRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [complaintList, setComplaintList] = useState([]);
    const [company, setCompany] = useState(null);
    const [resetFilter, setResetFilter] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    // // validation
    // const validation = useFormik({
    //     // enableReinitialize : use this flag when initial values needs to be changed
    //     enableReinitialize: true,

    //     initialValues: {
    //         Id: (company && company.Id) || '',
    //         companyName: (company && company.companyName) || '',
    //         companyEmail: (company && company.CompanyEmail) || '',
    //         phoneNo : (company && company.phoneNo) || '',
    //         location: (company && company.location) || '',
    //         approvalStatus: (company && company.approvalStatus) || '',
    //         updatedOn: (company && company.updatedOn) || '',
    //         status: (company && company.status) || '',

    //     },
    //     validationSchema: Yup.object({
    //         // Id: Yup.string().matches(
    //         //     /[0-9\.\-\s+\/()]+/,
    //         //     "Please Enter Valid Job Id"
    //         // ).required("Please Enter Your Job Id"),
    //         // jobTitle: Yup.string().required("Please Enter Your Job Title"),
    //         companyName: Yup.string().required("Please Enter Your Company Name"),
    //         companyEmail: Yup.string().required("Please Enter Your Company Email"),
    //         phoneNo: Yup.string().required("Please Enter Your Company Phone Number"),
    //         location: Yup.string().required("Please Enter Your Location"),
    //         approvalStatus: Yup.string().required("Please Enter Your Experience"),
    //         updatedOn: Yup.string().required("Please Enter Your Position"),
    //         status: Yup.string().required("Please Enter Your Status"),
    //     }),
    //     onSubmit: (values) => {
    //         // if (isEdit) {
    //         //     const updateJobList = {
    //         //         id: job ? job.id : 0,
    //         //         jobId: values.jobId,
    //         //         jobTitle: values.jobTitle,
    //         //         companyName: values.companyName,
    //         //         location: values.location,
    //         //         experience: values.experience,
    //         //         position: values.position,
    //         //         type: values.type,
    //         //         postedDate: "02 June 2021",
    //         //         lastDate: "25 June 2021",
    //         //         status: values.status,
    //         //     };
    //         //     // update Job
    //         //     dispatch(onUpdateJobList(updateJobList));
    //         //     validation.resetForm();
    //         // } else {
    //         //     const newJobList = {
    //         //         id: Math.floor(Math.random() * (30 - 20)) + 20,
    //         //         jobId: values["jobId"],
    //         //         jobTitle: values["jobTitle"],
    //         //         companyName: values["companyName"],
    //         //         location: values["location"],
    //         //         experience: values["experience"],
    //         //         position: values["position"],
    //         //         type: values["type"],
    //         //         postedDate: "02 June 2021",
    //         //         lastDate: "25 June 2021",
    //         //         status: values["status"],
    //         //     };
    //         //     // save new Job
    //         //     dispatch(onAddNewJobList(newJobList));
    //         //     validation.resetForm();
    //         // }
    //         // toggle();
    //     },
    // });

    const { error, loading, complaints } = useSelector(state => ({
        error: state.complaints.error,
        loading : state.complaints.loading,
        complaints : state.complaints.complaints

      }));
    
    
      useEffect(() => {
        setIsLoading(loading)
    }, [loading]);

    useEffect(() => {

        // console.log(JSON.parse(localStorage.getItem(sessionStorage)))
        if (complaints && !complaints.length) {
            
            dispatch(getComplaintUser());
        }
    }, [dispatch]);

    useEffect(() => {
        // console.log(complaints)

        setComplaintList(complaints);
   
    }, [complaints]);

    useEffect(() => {
        if (!isEmpty(complaints) && !!isEdit) {
            // setCc(complaints);
            setIsEdit(false);
        }
    }, [complaints]);

    const toggle = () => {
        if (modal) {
            setModal(false);
            setCompany(null);
        } else {
            setModal(true);
        }
    };

    // const handleJobClick = arg => {
    //     const job = arg;
    //     setJob({
    //         id: job.id,
    //         jobId: job.jobId,
    //         jobTitle: job.jobTitle,
    //         companyName: job.companyName,
    //         location: job.location,
    //         experience: job.experience,
    //         position: job.position,
    //         type: job.type,
    //         status: job.status,
    //     });

    //     setIsEdit(true);

    //     toggle();
    // };

    //delete Company
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (company) => {
        setCompany(company);
        setDeleteModal(true);
    };

    const handleDeletecompany = () => {
        // console.log(company)
        if (company && company.FLD_COMPANYID) {
            dispatch(onDeleteCompany(company.FLD_COMPANYID));
            setDeleteModal(false);
        }
    };
    const handleCompanyClicks = () => {
        setCompanyList("");
        setIsEdit(false);
        toggle();
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Complaint No',
                accessor: 'COMPLAINT_NUM',
                filterable: true,
                Cell: (cellProps) => {
                    return <CompanyName {...cellProps} />;
                }
            },

            // {
            //     Header: 'Title',
            //     accessor: 'No2',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <No {...cellProps} />;
            //     }
            // },
            {
                Header: 'Complaint Date',
                accessor: 'FLD_CREATEDON',
                filterable: true,
                Cell: (cellProps) => {
                    return <UpdateOn {...cellProps} />;
                }
            },

            // {
            //     Header: 'Status',
            //     accessor: 'FLD_STATUS',
            //     filterable: true,
            //     // disableFilters: true,
            //     Cell: (cellProps) => {
            //         return <Status {...cellProps} />;
            //     }
            // },
           
            {
                Header: 'Parent Company',
                accessor: 'ORG_NAME',
                filterable: true,
                Cell: (cellProps) => {
                    return <CompanyName {...cellProps} />;
                }
            },
            {
                Header: 'Device/Brand Name',
                accessor: 'DEVICE_NAME',
                filterable: true,
                Cell: (cellProps) => {
                    return <CompanyName {...cellProps} />;
                }
            },
            
            {
                Header: 'Email Sent To',
                accessor: 'ORG_NAME1',
                filterable: true,
                Cell: (cellProps) => {
                        return (
                            <div>Support</div>
                        )

                }
            },

            {
                Header: 'Date Updated On',
                accessor: 'FLD_LASTUPDATEDON',
                filterable: true,
                Cell: (cellProps) => {
                    return <UpdateOn {...cellProps} />;
                }
            },

           

            {
                Header: 'Action',
                accessor: 'action',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                <Link 
                                to={'/alter-complaint'} state={{ state: {...cellProps.row.original, isEditable : false} }}
                                className="btn btn-sm btn-soft-warning">
                                    <i className="mdi mdi-eye-outline" id="viewtooltip"></i></Link>
                            </li>
                            <UncontrolledTooltip placement="top" target="viewtooltip">
                                View
                            </UncontrolledTooltip>

                            <li>
                                <Link
                                    // to="#"
                                    to={'/alter-complaint'} state={{ state: {...cellProps.row.original, isEditable : true} }}
                                    // to={{ pathname: '/alter-corporate-profile', state: { data : cellProps.row.original } }}
                                    className="btn btn-sm btn-soft-info"
                                   
                                >
                                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                    <UncontrolledTooltip placement="top" target="edittooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            {/* <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        const companyData = cellProps.row.original;
                                        onClickDelete(companyData);
                                    }}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li> */}
                        </ul>
                    );
                }
            },
        ],
        []
    );

      // Reset filters
  const handleReset = () => {
    tableRef.current.resetTable(); 
    // console.log(tableRef.current)
  };

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                deleteMsg={'Are you sure you want to archive the organisation.'}
                onDeleteClick={handleDeletecompany}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Manage Complaints" breadcrumbItem="Complaint List" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">List Of Complaints associated</h5>

                                        <Button color="primary"  className='margin-right-top-dt'
                                        onClick={() => {
                                            navigate('/create-complaint')
                                        }}>
                                            <i className="mdi mdi-book-plus" style={{marginRight : 7}}/>
                                            New Complaint</Button>

                                        <div className="flex-shrink-0">
                                            <Link className="btn btn-light me-3" onClick={handleReset}><i className="mdi mdi-refresh"
                                            
                                            ></i> Reset Filters </Link>
                                            {/* <UncontrolledDropdown className="dropdown d-inline-block me-1">
                                                <DropdownToggle type="menu" className="btn btn-light" id="dropdownMenuButton1">
                                                    <i className="mdi mdi-dots-vertical"></i></DropdownToggle>
                                                <DropdownMenu>
                                                    <li><DropdownItem href="#">Action</DropdownItem></li>
                                                    <li><DropdownItem href="#">Another action</DropdownItem></li>
                                                    <li><DropdownItem href="#">Something else here</DropdownItem></li>
                                                </DropdownMenu>
                                            </UncontrolledDropdown> */}
                                        </div>
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <TableContainer
                                        ref={tableRef}
                                        columns={columns}
                                        data={complaints}
                                        isGlobalFilter={true}
                                        isAddOptions={false}
                                        handleCompanyClicks={handleCompanyClicks}
                                        // resetFilter={resetFilter}
                                        isCompanyListGlobalFilter={true}
                                        customPageSize={10}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {/* <Modal isOpen={modal} toggle={toggle} className="model-width80">
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? "Edit Company Profile" : "Add Company Profile"}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                            <Label className="form-label"> Job Id</Label>
                                            <Input
                                                name="jobId"
                                                type="text"
                                                placeholder="Insert Job Id"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.jobId || ""}
                                                invalid={
                                                    validation.touched.jobId && validation.errors.jobId ? true : false
                                                }
                                            />
                                            {validation.touched.jobId && validation.errors.jobId ? (
                                                <FormFeedback type="invalid">{validation.errors.jobId}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Job Title</Label>
                                            <Input
                                                name="jobTitle"
                                                type="text"
                                                placeholder="Insert Job Title"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.jobTitle || ""}
                                                invalid={
                                                    validation.touched.jobTitle && validation.errors.jobTitle ? true : false
                                                }
                                            />
                                            {validation.touched.jobTitle && validation.errors.jobTitle ? (
                                                <FormFeedback type="invalid">{validation.errors.jobTitle}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Company Name</Label>
                                            <Input
                                                name="companyName"
                                                type="text"
                                                placeholder="Insert Company Name"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.companyName || ""}
                                                invalid={
                                                    validation.touched.companyName && validation.errors.companyName ? true : false
                                                }
                                            />
                                            {validation.touched.companyName && validation.errors.companyName ? (
                                                <FormFeedback type="invalid">{validation.errors.companyName}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Location</Label>
                                            <Input
                                                name="location"
                                                placeholder="Insert Location"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.location || ""}
                                                invalid={
                                                    validation.touched.location && validation.errors.location ? true : false
                                                }
                                            />
                                            {validation.touched.location && validation.errors.location ? (
                                                <FormFeedback type="invalid">{validation.errors.location}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Experience</Label>
                                            <Input
                                                name="experience"
                                                type="text"
                                                placeholder="Insert Experience"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.experience || ""
                                                }
                                                invalid={
                                                    validation.touched.experience && validation.errors.experience ? true : false
                                                }
                                            />
                                            {validation.touched.experience && validation.errors.experience ? (
                                                <FormFeedback type="invalid">{validation.errors.experience}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Position</Label>
                                            <Input
                                                name="position"
                                                type="text"
                                                placeholder="Insert Position"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.position || ""}
                                                invalid={
                                                    validation.touched.position && validation.errors.position ? true : false
                                                }
                                            />
                                            {validation.touched.position && validation.errors.position ? (
                                                <FormFeedback type="invalid">{validation.errors.position}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Type</Label>
                                            <Input
                                                name="type"
                                                type="select"
                                                className="form-select"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.type || ""
                                                }
                                                invalid={
                                                    validation.touched.type && validation.errors.type
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <option>Full Time</option>
                                                <option>Part Time</option>
                                                <option>Freelance</option>
                                                <option>Internship</option>

                                            </Input>
                                            {validation.touched.type && validation.errors.type ? (
                                                <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Status</Label>
                                            <Input
                                                name="status"
                                                type="select"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.status || ""
                                                }
                                                invalid={
                                                    validation.touched.status && validation.errors.status ? true : false
                                                }
                                            >
                                                <option>Active</option>
                                                <option>New</option>
                                                <option>Close</option>
                                            </Input>
                                            {validation.touched.status && validation.errors.status ? (
                                                <FormFeedback status="invalid">{validation.errors.status}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal> */}
                    
                </div>
                
            </div>

        <Loader isLoading={isLoading} /> 
        </React.Fragment>
    );
}


export default ComplaintList;