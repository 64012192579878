// debounceMiddleware.js

const debounceMiddleware = (delay = 300) => {
    const pendingActions = {};
  
    return (store) => (next) => (action) => {
      const { type } = action;
  
      // Clear existing timeout for this action type
      if (pendingActions[type]) {
        clearTimeout(pendingActions[type]);
      }
  
      // Set a new timeout for the action type
      pendingActions[type] = setTimeout(() => {
        next(action);
        delete pendingActions[type]; // Clean up after execution
      }, delay);
    };
  };
  
  export default debounceMiddleware;
  