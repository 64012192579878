import { ADD_EVENT_ANSWER_CHECKLIST_FAIL, ADD_EVENT_ANSWER_CHECKLIST_SUCCESS, ADD_EVENT_ANSWER_FAIL, ADD_EVENT_ANSWER_REGULAR_FAIL, ADD_EVENT_ANSWER_REGULAR_SUCCESS, ADD_EVENT_ANSWER_SUCCESS, ADD_EVENT_COMPLAINT, ADD_EVENT_COMPLAINT_FAIL, ADD_EVENT_COMPLAINT_SUCCESS, GET_COMPLAINT_DEVICES_SUCCESS, GET_COMPLAINT_PARENT_COMPANY_FAIL, GET_COMPLAINT_PARENT_COMPANY_SUCCESS, GET_COMPLAINT_USER_FAIL, GET_COMPLAINT_USER_SUCCESS, GET_EVENT_ANSWER_FAIL, GET_EVENT_ANSWER_SUCCESS, GET_PARENT_COMPANY_QUESTION_DATA_FAIL, GET_PARENT_COMPANY_QUESTION_DATA_SUCCESS, SAVE_MAILER_ANSWERS, SET_COMPLAINT_MODULE_DATA, SET_LOADING_INDICATOR_COMPLAINT, UPDATE_EVENT_COMPLAINT_FAIL, UPDATE_EVENT_COMPLAINT_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  complaints: [],
  parent_companys: [],
  devices: [],
  question_data : [],
  module_data : [],
  answers : [],
  error: {},
  answer_data : [],
  loading: false

}

const complaints = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPLAINT_USER_SUCCESS:
      return {
        ...state,
        complaints: action.payload,
        loading: false
      }

    case GET_COMPLAINT_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

      case GET_COMPLAINT_PARENT_COMPANY_SUCCESS:
        return {
          ...state,
          parent_companys: action.payload,
          loading: false
        }
  
      case GET_COMPLAINT_PARENT_COMPANY_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false
        }

        case GET_COMPLAINT_DEVICES_SUCCESS:
          return {
            ...state,
            devices: action.payload,
            loading: false
          }
    
        case GET_COMPLAINT_PARENT_COMPANY_FAIL:
          return {
            ...state,
            error: action.payload,
            loading: false
          }

          case GET_PARENT_COMPANY_QUESTION_DATA_SUCCESS:
            return {
              ...state,
              question_data: action.payload,
              loading: false
            }
      
          case GET_PARENT_COMPANY_QUESTION_DATA_FAIL:
            return {
              ...state,
              error: action.payload,
              loading: false
            }

            case SET_COMPLAINT_MODULE_DATA:
              return {
                ...state,
                module_data: action.payload,
                loading: false
              }
        
              case ADD_EVENT_ANSWER_SUCCESS:
                return {
                  ...state,
                  loading: false
                }
          
              case ADD_EVENT_ANSWER_FAIL:
                return {
                  ...state,
                  error: action.payload,
                  loading: false
                }
          
                case ADD_EVENT_ANSWER_CHECKLIST_SUCCESS:
                  return {
                    ...state,
                    loading: false
                  }
            
                case ADD_EVENT_ANSWER_CHECKLIST_FAIL:
                  return {
                    ...state,
                    error: action.payload,
                    loading: false
                  }
                  
                  case ADD_EVENT_ANSWER_REGULAR_SUCCESS:
                    return {
                      ...state,
                      // questionData: action.payload,
                      loading: false
                    }
              
                  case ADD_EVENT_ANSWER_REGULAR_FAIL:
                    return {
                      ...state,
                      error: action.payload,
                      loading: false
                    }
                    

                    case ADD_EVENT_COMPLAINT_SUCCESS:
                      return {
                        ...state,
                        // questionData: action.payload,
                        loading: false
                      }
                
                    case ADD_EVENT_COMPLAINT_FAIL:
                      return {
                        ...state,
                        error: action.payload,
                        loading: false
                      }
                      

                      case GET_EVENT_ANSWER_SUCCESS:
                        return {
                          ...state,
                          answers : action.payload,
                          loading: false
                        }
                  
                      case GET_EVENT_ANSWER_FAIL:
                        return {
                          ...state,
                          error: action.payload,
                          loading: false
                        }

                        case SAVE_MAILER_ANSWERS:
                          return {
                            ...state,
                            answer_data : action.payload,
                            loading: false
                          }
                    

                          case UPDATE_EVENT_COMPLAINT_SUCCESS:
                            return {
                              ...state,
                              loading: false
                            }
                      
                          case UPDATE_EVENT_COMPLAINT_FAIL:
                            return {
                              ...state,
                              error: action.payload,
                              loading: false
                            }
    
          
    case SET_LOADING_INDICATOR_COMPLAINT:
      return {
        ...state,
        loading: action.payload
      };

    default:
      return state
  }
}

export default complaints
