import React, { useState, useEffect } from "react"

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
  Badge,
  UncontrolledTooltip,
  FormFeedback,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import classnames from "classnames"
import { Link , useNavigate} from "react-router-dom"
import Select from 'react-select';
import Switch from "react-switch";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import * as Yup from "yup";
import { useFormik } from "formik";

import { addEventComplaint, getComplaintDevices, getComplaintParentCompany, getParentCompanyQuestionData } from "store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import { authUser } from "helpers/dataConfig";
import Loader from "components/Common/Loader";
import moment from "moment";
import { v4 as uuid } from "uuid";
import RegularBranching from "./ComplaintDecisionTree/RegularBranching";
import DetailedBranching from "./ComplaintDecisionTree/DetailedBranching";
import Checklist from "./ComplaintDecisionTree/Checklist";
import Chatbot from "./ComplaintDecisionTree/Chatbot";
import CreatableSelect from 'react-select/creatable';
// import DetailedBranching from "./ComplaintDecisionTree/DetailedBranching";
// import Checklist from "./ComplaintDecisionTree/Checklist";
// import Chatbot from "./ComplaintDecisionTree/Chatbot";

const AddComplaint = () => {

  //meta title
  document.title = "Add New Complaint | Smarteeva";

  const navigate = useNavigate()

  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  const [deviceOptions, setDeviceOptions] = useState([])
  const [parentCompanyOptions, setParentCompanyOptions] = useState([])
  const [questionData, setQuestionData] = useState([])

  const [eventId, setEventId] = useState('')

  const [numRegex, setNumRegex] = useState(/^[0-9]*$/)

  const [isLoading, setIsLoading] = useState(false);


  const [open, setOpen] = useState('1');
  const [open2, setOpen2] = useState('2');



  const dispatch = useDispatch();
  
  const { error, loading, parent_companys, devices, question_data, module_data, answer_data } = useSelector(state => ({
    error: state.complaints.error,
    loading : state.complaints.loading,
    parent_companys : state.complaints.parent_companys,
    devices : state.complaints.devices,
    question_data : state.complaints.question_data,
    module_data : state.complaints.module_data,
    answer_data : state.complaints.answer_data


  }));

  useEffect(() => {
    setIsLoading(loading)
}, [loading]);

 

useEffect(() => {
  // console.log(parent_companys)
  setParentCompanyOptions(parent_companys)
}, [parent_companys]);

useEffect(() => {
  // console.log(companys)
  setDeviceOptions(devices)
}, [devices]);

useEffect(() => {
  // console.log(parent_companys)
  setQuestionData(question_data)
}, [question_data]);

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
        title: '',
        parent_company: '',
        device : '',
        complaint_date : '',
        parent_company_registered : null

    },
    validationSchema: Yup.object({
        // Id: Yup.string().matches(
        //     /[0-9\.\-\s+\/()]+/,
        //     "Please Enter Valid Job Id"
        // ).required("Please Enter Your Job Id"),
        // jobTitle: Yup.string().required("Please Enter Your Job Title"),
        parent_company: Yup.string().required("Please Select Parent Company"),
        device: Yup.string().nullable().required("Please Select Device/Brand Name")

    }),
    onSubmit: (values) => {
      // console.log(values)
      // console.log(eventId)
      let data = {
        user_id : JSON.parse(localStorage.getItem(authUser)).fld_userid,
        device_id : 0,
        device_name : values.device,
        event_uid : eventId,
        org_id : 0,
        org_name : values.parent_company,
        comments : '',
        title : values.title,
        complaint_date : values.complaint_date == '' ? moment(new Date()).format('MM/DD/YYYY') : values.complaint_date,
        complaint_uid : uuid(),
        moduleid : module_data[0].FLD_MODULEID,
      }
      // let ans_dt = {
      //   answer_data : answer_data
      // }
      // console.log(answer_data)
      dispatch(addEventComplaint(data, navigate, answer_data, values.parent_company_registered))
    },
});




const handleKeyDown = (e) => {
  // console.log(e.key)
  if (e.key.match(/[a-z]/i) && e.key!= 'Backspace') {
    e.preventDefault();
  }
};


const handleInputChange = (inputValue) => {
 if(inputValue != ''){
  console.log('Search input:', inputValue);
  dispatch(getComplaintParentCompany(inputValue));
 }
  // dispatch(getComplaintParentCompany(inputValue));
  // You can use this inputValue to make API calls to fetch matching data
};

const handleInputChangeDevice = (inputValue) => {
  if(inputValue != ''){
  //  console.log('Search input:', inputValue);
  //  dispatch(getComplaintDevices(inputValue));
  }
   // dispatch(getComplaintParentCompany(inputValue));
   // You can use this inputValue to make API calls to fetch matching data
 };


 const renderModule = (moduleType) => {
  // console.log(moduleType)
  switch (moduleType) {
    case 'Regular Branching':
      // console.log(questionDetails)
      return questionData.length > 0 ? <RegularBranching questions={questionData} eventData={{EVENT_ID : eventId}} ansData={[]} type={'add'}/> : '';
    case 'Detailed Branching':
      return questionData.length > 0 ? <DetailedBranching questions={questionData} eventData={{EVENT_ID : eventId}} ansData={[]} type={'add'}/> : '';
    case 'Checklist':
      return questionData.length > 0 ? <Checklist questions={questionData} eventData={{EVENT_ID : eventId}} ansData={[]} type={'add'}/> : '';
    case 'Chat bot':
      return questionData.length > 0 ? <Chatbot questions={questionData} eventData={{EVENT_ID : eventId}} ansData={[]} type={'add'} /> : '';
    default:
    // return <div>Please select a module type.</div>;
  }
};


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Manage Complaints" breadcrumbItem="Create New Complaint" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Create New Complaint</CardTitle>
                  {/* <p className="text-muted mb-5">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ultricies vel enim in malesuada. Nulla porta tempor placerat. Aenean vitae hendrerit lacus, vitae sollicitudin ante. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam tristique et mauris nec iaculis.
                  </p> */}
                  <div className="horizontal-wizard wizard clearfix horizontal">
                    <div className="wizard clearfix">
                  
                    </div>
                    <div className="content clearfix">
                      <TabContent
                        activeTab={activeTabVartical}
                        className="body"
                      >
                        <TabPane tabId={1}>
                          <Form onSubmit={(e) => {
                            e.preventDefault();
                            // validation.handleSubmit();
                            // return false;
                          }}>

<Accordion open={open} toggle={(id)=>{
  if (open === id) {
    setOpen();
  } else {
    setOpen(id);
  }
}} className="mb-3 accordian-complaints">
        <AccordionItem>
          <AccordionHeader targetId="1">General Information</AccordionHeader>
          <AccordionBody accordionId="1">

                                                         <Row>

                                                         <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label >
                                   Reason for Complaint
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Complaint Title"
                                    name="title"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.title || ""}
                                    invalid={
                                      validation.touched.title && validation.errors.title ? true : false
                                    }
                                  />
                                  {validation.touched.title && validation.errors.title ? (
                                    <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                                  ) : null}
                               
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                              <FormGroup className="mb-3">
                                  <Label  className="required">Parent Company</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  isClearable
                                  isSearchable
                                  name={'parent_company'}
                                  onChange={(e)=>{
                                    setQuestionData([])
                                    if(e){
                                    validation.setFieldValue('parent_company', e.value)
                                    validation.setFieldError('parent_company',false)
                                    // console.log(e)
                                    validation.setFieldValue('parent_company_registered', e.is_registered)
                                    
                                    dispatch(getComplaintDevices(e.value));

                                  
                                    }else{
                                      validation.setFieldValue('parent_company', '')
                                    validation.setFieldError('parent_company',true)
                                    validation.setFieldValue('parent_company_registered', '')
                                    }
                                  }}
                                  onInputChange={handleInputChange}
                                  onBlur={validation.handleBlur}
                                  options={parentCompanyOptions}
                                  value={{label : validation.values.parent_company, value : validation.values.parent_company}}
                                // formatOptionLabel={({ value, label }) => `(${value}) ${label}`}
                                
                                
                                className={validation.touched.parent_company && validation.errors.parent_company ? 'invalid-select' : ''}
                                // invalid={
                                //     validation.touched.country && validation.errors.country
                                //         ? true
                                //         : false
                                // }
                              
                                />
                                  {validation.touched.parent_company && validation.errors.parent_company ? (
                                    <FormFeedback type="invalid">{validation.errors.parent_company}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                              <FormGroup className="mb-3">
                                  <Label className="required">Device / Brand Name</Label>

                                  <CreatableSelect
                                      isClearable
                                      isSearchable
                                      name={'device'}
                                      onChange={(e)=>{
                                        setQuestionData([])
                                        if(e){
                                        validation.setFieldValue('device', e.value)
                                        validation.setFieldError('device',false)

                                        let p_data = {
                                          parent_company : validation.values.parent_company.toLowerCase().trim(),
                                          device : e.value.toLowerCase().trim()
                                        }
                                        dispatch(getParentCompanyQuestionData(p_data));
                                        setEventId(uuid())
                                        }else{
                                          validation.setFieldValue('device', e)
                                        validation.setFieldError('device',true)
                                        }
                                      }}

                                      onBlur={validation.handleBlur}
                                      options={deviceOptions}
                                      // onInputChange={handleInputChangeDevice}
                                      value={{label : validation.values.device, value : validation.values.device}}
                                      // formatOptionLabel={({ value, label }) => `(${value}) ${label}`}


                                      className={validation.touched.device && validation.errors.device ? 'invalid-select' : ''}
                                      // invalid={
                                      //     validation.touched.country && validation.errors.country
                                      //         ? true
                                      //         : false
                                      // }
                                  />

                                  {/* <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  isClearable
                                  isSearchable
                                  name={'device'}
                                  onChange={(e)=>{
                                    if(e){
                                    validation.setFieldValue('device', e.value)
                                    validation.setFieldError('device',false)

                                    }else{
                                      validation.setFieldValue('device', e)
                                    validation.setFieldError('device',true)
                                    }
                                  }}
                              
                                  onBlur={validation.handleBlur}
                                  options={deviceOptions}
                                  onInputChange={handleInputChangeDevice}
                                  value={{label : validation.values.device, values : validation.values.device}}
                                // formatOptionLabel={({ value, label }) => `(${value}) ${label}`}
                                
                                
                                className={validation.touched.device && validation.errors.device ? 'invalid-select' : ''}
                                // invalid={
                                //     validation.touched.country && validation.errors.country
                                //         ? true
                                //         : false
                                // }
                              
                                /> */}
                                  {validation.touched.device && validation.errors.device ? (
                                    <FormFeedback type="invalid">{validation.errors.device}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            


                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label >
                                    Complaint Date
                                  </Label>
                                  {/* <Input
                                    // disabled
                                    type="Date"
                                    className="form-control"
                                    placeholder="This field is calculated upon save"
                                    name="lname"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.lname || ""}
                                    invalid={
                                      validation.touched.lname && validation.errors.lname ? true : false
                                    }
                                  /> */}

                                        <Flatpickr
                                                    className="form-control d-block"
                                                    placeholder="Select complaint date"
                                                    isClearable
                                                    name="complaint_date"
                                                    options={{
                                                        altInput: true,
                                                        altFormat: "F j, Y",
                                                        dateFormat: "Y-m-d"
                                                    }}
                                                    onChange={(e)=>{
                                                      // console.log(moment(new Date(e)).format('MM/DD/YYYY'))
                                                      if(e){
                                                        validation.setFieldValue('complaint_date', moment(new Date(e)).format('MM/DD/YYYY'))
                                                        validation.setFieldError('complaint_date',false)
                    
                                                        }else{
                                                          validation.setFieldValue('complaint_date', '')
                                                        validation.setFieldError('complaint_date',true)
                                                        }
                                                    }}
                                                    invalid={
                                                      validation.touched.complaint_date && validation.errors.complaint_date ? true : false
                                                    }
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.complaint_date != '' ? new Date(validation.values.complaint_date) : new Date()}
                                                />
                                  {validation.touched.complaint_date && validation.errors.complaint_date ? (
                                    <FormFeedback type="invalid">{validation.errors.complaint_date}</FormFeedback>
                                  ) : null}
                               
                                </FormGroup>
                              </Col>

                             
                            </Row>

                                                        </AccordionBody>
                                                        </AccordionItem>
                                                        </Accordion>
                          
                           
                                                        <Accordion open={open2} toggle={(id)=>{
  if (open2 === id) {
    setOpen2();
  } else {
    setOpen2(id);
  }
}} className="mb-3 accordian-complaints">
        <AccordionItem>
          <AccordionHeader targetId="2">Complaint Details</AccordionHeader>
          <AccordionBody accordionId="2">
                            <Row>
                             
                            {module_data != '' && validation.values.parent_company != '' ? renderModule(module_data[0].FLD_TYPE) : <p>
                              <h6>
                                Kindly select the Parent Company and Brand Name of the device to process the Complaint.</h6></p>}
                            </Row>
                           </AccordionBody>
                           </AccordionItem>
                           </Accordion>
                          
                          </Form>
                        </TabPane>

                      

                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                       


                        <li
                         style={{float  : 'right'}}
                          // className={
                          //   activeTabVartical < 2 ? "next disabled" : "next"
                          // }
                        >
                        
                          <Link
                            to="#"
                            onClick={(e) => {
                              e.preventDefault()
                              validation.handleSubmit();
                            }}
                          >
                           Save
                          </Link>
                        </li>

                        <li
                         style={{float  : 'right'}}
                          className={ "next"}
                        >
                        
                          <Link
                           to="/complaints"
                            className="danger-btn"
                            // onClick={() => {
                            //   navigate('/corporate-list')
                            // }}
                          >
                           Cancel
                          </Link>
                        </li>

                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Loader isLoading={isLoading} /> 

    </React.Fragment>
  )
}

export default AddComplaint
