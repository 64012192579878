import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
import { Amplify } from 'aws-amplify'
import config from './aws-config'
import store from "./store";

Amplify.configure({
  Auth : {
      mandatorySignId : true,
      region : config.cognito.REGION,
      userPoolId : config.cognito.USER_POOL_ID,
      userPoolWebClientId : config.cognito.APP_CLIENT_ID,
      oauth: {
        domain : config.oauth.domain,
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: config.oauth.redirectSignIn,
        redirectSignOut: config.oauth.redirectSignOut,
        responseType: config.oauth.responseType,

    }
  }
})



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
      <React.Fragment>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.Fragment>
    </Provider>
);

serviceWorker.unregister()



