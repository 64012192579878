import React, { useState, useRef, useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './landing.css';
import { Container, Row, Col, Button, Form, Card, CardBody, CardTitle, Collapse } from 'react-bootstrap';
import { FaDollarSign, FaTasks, FaChartLine, FaCheckCircle } from 'react-icons/fa';
import Login from "./Login";
import Register from "./Register";
import { useSelector, useDispatch } from "react-redux";
import Loader from "components/Common/Loader";
import Component01 from './img/Component 1.svg';
import Component02 from './img/Component 2.svg';
import Component03 from './img/Component 3.svg';
import Component04 from './img/Component 4.svg';
import Component05 from './img/Component 5.svg';
import Component06 from './img/Component 6.svg';
import classnames from "classnames";

function LoginLanding() {
  const [showLogin, setShowLogin] = useState(false);
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [activeSection, setActiveSection] = useState(''); // State to track active section
  const [isLoading, setIsLoading] = useState(false);


  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);

  const [col5, setcol5] = useState(true);
  const [col6, setcol6] = useState(true);
  const [col7, setcol7] = useState(true);

  const [col8, setcol8] = useState(true);
  const [col9, setcol9] = useState(true);
  const [col10, setcol10] = useState(false);
  const [col11, setcol11] = useState(false);

  

  const loginItemRef = useRef(null);
  const registerItemRef = useRef(null);

  const { error, loading } = useSelector(state => ({
    error: state.Login.error,
    loading : state.Login.loading
  }));

  const t_col1 = () => {
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
  };

  const t_col2 = () => {
    setcol2(!col2);
    setcol1(false);
    setcol3(false);
  };

  const t_col3 = () => {
    setcol3(!col3);
    setcol1(false);
    setcol2(false);
  };

  const t_col5 = () => {
    setcol5(!col5);
  };

  const t_col6 = () => {
    setcol6(!col6);
  };

  const t_col7 = () => {
    setcol7(!col7);
  };

  const t_col8 = () => {
    setcol6(!col6);
    setcol7(!col7);
  };

  const t_col9 = () => {
    setcol9(!col9);
    setcol10(false);
    setcol11(false);
  };

  const t_col10 = () => {
    setcol10(!col10);
    setcol9(false);
    setcol11(false);
  };

  const t_col11 = () => {
    setcol11(!col11);
    setcol10(false);
    setcol9(false);
  };

  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  };

  const handleLoginClick = () => {

    setShowLogin(!showLogin); // Toggle login section
    setShowCreateAccount(false); // Hide create account section if it's open
    // Toggle login class: if 'login-active' is already the active section, remove it, otherwise add it
    setActiveSection((prev) => (prev === 'login-active' ? '' : 'login-active'));

    showLogin ? '' : scrollToSection(loginItemRef)
    // console.log(loginItemRef.current)
    // showLogin ? '' : loginItemRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  };

  const scrollToSection = (sectionRef) => {
    const top = sectionRef.current.offsetTop; // Get the offset top position of the section
    window.scrollTo({
      top: top,
      behavior: "smooth", // For smooth scrolling
    });
  };

  const handleCreateAccountClick = () => {
    setShowCreateAccount(!showCreateAccount); // Toggle create account section
    setShowLogin(false); // Hide login section if it's open
    // Toggle create account class: if 'create-account-active' is already the active section, remove it, otherwise add it
    setActiveSection((prev) => (prev === 'create-account-active' ? '' : 'create-account-active'));

    showCreateAccount ? '' :  scrollToSection(registerItemRef)
    // console.log(registerItemRef.current)
    // showCreateAccount ? '' : registerItemRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  };

  useEffect(() => {
  
    setIsLoading(loading)
 
}, [loading]);

  return (
    <div>
      {/* Header Section */}
      <section className="header-section text-center">
        <Row>
          <Col md={12} className="header-section02">
            <div className="logo-wrap">
              <img src="https://smarteeva.com/wp-content/uploads/2019/05/Logo-Smarteeva-sm.svg" alt="Smarteeva Logo" className="mb-4" />
            </div>
            <h1 className="heading">SMART COMPLAINT <br /> MANAGEMENT</h1>
            <div className="button-wrap">
              <Button className="me-3 button-header001" onClick={handleLoginClick}>Login</Button>
              <Button className="button-header002" onClick={handleCreateAccountClick}>Create an Account</Button>
            </div>
            <div className="video-wrapper">
              <iframe
                src="https://player.vimeo.com/video/1002946498" // Replace with your Vimeo video ID
                width="640"
                height="360"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="Vimeo video player"
              ></iframe>
            </div>
          </Col>
        </Row>
      </section>

      <section ref={registerItemRef}>
    <section ref={loginItemRef}>
      {/* Login Form Section */}
      {showLogin && (
        <section className="login-section" >
          {/* <Container className="paddingtop130">
            <Row>
            
              <Col md={6} className="d-flex flex-column justify-content-center align-items-center signin-left">
                <img
                  src="https://smarteeva.com/wp-content/uploads/2019/05/Logo-Smarteeva-sm.svg"
                  alt="Smarteeva Logo"
                  className="mb-4"
                  style={{ marginRight: '10px' }}
                />
                <h1 className="welcome-title">Welcome back to Smarteeva</h1>
                <p className="welcome-text">
                  We are lorem ipsum team dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </Col>

             
              <Col md={6} className="d-flex justify-content-center align-items-center signin-right">
                <div className="signin-form-container">
                  <h3 className="signin-heading">Sign in</h3>
                  <p>
                    Don’t have an account? <a href="#signup" className="signup-link">Sign Up</a>
                  </p>
                  <Form>
                    <Form.Group controlId="formBasicEmail" className="mb-3">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control type="email" placeholder="Enter email" />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword" className="mb-3">
                      <Form.Label>Password</Form.Label>
                      <Form.Control type="password" placeholder="Password" />
                    </Form.Group>
                    <div className="d-flex justify-content-between">
                      <a href="#forgot" className="forgot-password-link">Forgot password?</a>
                    </div>
                    <Button variant="primary" type="submit" className="signin-btn mt-3">
                      Sign In
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container> */}
          <Login  setLogin={handleCreateAccountClick} />
        </section>
      )}
      </section>

      {/* Create Account Form Section */}
      {/* <section ref={registerItemRef}> */}
      {showCreateAccount && (
        <section className="create-account-section" >

            <Register setRegister={handleLoginClick}/>
          {/* <Container className="paddingtop130">
            <Row className="justify-content-center">
           
              <Col md={6} className="d-flex flex-column justify-content-center align-items-center signup-left">
                <img
                  src="https://smarteeva.com/wp-content/uploads/2019/05/Logo-Smarteeva-sm.svg"
                  alt="Smarteeva Logo"
                  className="mb-4"
                />
                <h1 className="welcome-title">Create an Account with Smarteeva</h1>
                <p >
                  Experience why over 2 million customers choose Smarteeva Products
                </p>
                <div className="features py-2">
                  <Row className="text-left">
                    <Col md={6} className="feature">
                      <FaDollarSign size={30} className="mb-2 pb-2" />
                      <h5 className="feature-title">Lorem ipsum dolor</h5>
                      <p className="feature-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </Col>
                    <Col md={6} className="feature ">
                      <FaTasks size={30} className="mb-2 pb-2" />
                      <h5 className="feature-title">Lorem ipsum dolor</h5>
                      <p className="feature-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </Col>
                  </Row>
                </div>
              </Col>

            
              <Col md={6} className="d-flex justify-content-center align-items-center signup-right">
                <div className="signup-form-container">
                  <h3 className="signup-heading">Sign up</h3>
                  <p>
                    Already have an account? <a href="#signin" className="signin-link">Sign In</a>
                  </p>
                  <Form>
                    <Form.Group controlId="formBasicEmail" className="mb-3">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control type="email" placeholder="Enter email" />
                    </Form.Group>
                    <Form.Group controlId="formBasicFullName" className="mb-3">
                      <Form.Label>Full name</Form.Label>
                      <Form.Control type="text" placeholder="Enter full name" />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword" className="mb-3">
                      <Form.Label>Password</Form.Label>
                      <Form.Control type="password" placeholder="Password" />
                    </Form.Group>
                    <Form.Group controlId="formBasicConfirmPassword" className="mb-3">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control type="password" placeholder="Confirm Password" />
                    </Form.Group>
                    <Button variant="primary" type="submit" className="signup-btn mt-3">
                      Sign Up
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container> */}
        </section>
      )}
      </section>

      {/* Features Section */}
      <section className={`features-section ${activeSection}`}>
        <Container>
          <Row className="mb-5">
            <h4 className="features-subheading"><div className="rect mr-4"></div>Features</h4>
            {/* Left Column for Heading */}
            <Col md={6} className="features-text">
              <h3 className="features-heading">One-Stop Hub for  <br/> Device Complaints</h3>
            </Col>

            {/* Right Column for Text Content */}
            <Col md={6} className="features-content">
              <p className="features-description">
              Experience effortless complaint reporting with our lightning-fast, AI-based portal.
Customize AI-powered dynamic complaint forms, based on the device or company. 

              </p>
            </Col>
          </Row>

          {/* Feature Cards */}
          {/* <Row>
            <Col md={6} className="mb-4">
              <Card className="feature-card py-4">
                <div className="feature-icon">
                  <FaChartLine size={40} color="#007bff" />
                </div>
                <Card.Body>
                  <Card.Title>Lorem Ipsum</Card.Title>
                  <Card.Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Card.Text>
                  <a href="#learn-more" className="learn-more-link">Learn More &gt;</a>
                </Card.Body>
              </Card>
            </Col>

            <Col md={6} className="mb-4">
              <Card className="feature-card py-4">
                <div className="feature-icon">
                  <FaCheckCircle size={40} color="#007bff" />
                </div>
                <Card.Body>
                  <Card.Title>Lorem Ipsum</Card.Title>
                  <Card.Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Card.Text>
                  <a href="#learn-more" className="learn-more-link">Learn More &gt;</a>
                </Card.Body>
              </Card>
            </Col>
          </Row> */}
        </Container>
      </section>


        <div className="container">
        <div className="divider"></div>
        </div>

        <section className="more-features-section py-5">
      <Container>
        {/* Section Title */}
        <Row className="mb-5 text-center">
          <Col>
          <h4 className="features-subheading text-muted "><div className="rect mr-4"></div>More Features</h4>
              <h3 className="features-heading align-left "> We're more than a Smart Complaint Management tool.
              <br />Explore our easy to use complaint handling features</h3>
          </Col>
        </Row>

        {/* More Feature Cards */}
        <Row className="text-center">
          <Col md={4} className="mb-4">
            <div className="more-feature-card">
              <img src={Component01} alt="Smart Data Collection Icon" className="mb-3" />
              <h5 className="fw-bold">One-Click Complaint Submission</h5>
              <p className="text-muted">
              File your device complaints with minimal data entry in just one quick step.
              </p>
            </div>
          </Col>

          <Col md={4} className="mb-4">
            <div className="more-feature-card">
              <img src={Component02} alt="Complaint Management Icon" className="mb-3" />
              <h5 className="fw-bold">Automated Email Alerts</h5>
              <p className="text-muted">
              Instantly notify device owners and receive a detailed acknowledgment via email.
              </p>
            </div>
          </Col>

          <Col md={4} className="mb-4">
            <div className="more-feature-card">
              <img src={Component03} alt="Complaint Investigations Icon" className="mb-3" />
              <h5 className="fw-bold">Complaint Tracker</h5>
              <p className="text-muted">
              Monitor and manage your submitted complaints with easy-to-use tracking.

              </p>
            </div>
          </Col>

          <Col md={4} className="mb-4">
            <div className="more-feature-card">
              <img src={Component04} alt="Instant Complaint Reporting Icon" className="mb-3" />
              <h5 className="fw-bold">Editable Records On-the-Go</h5>
              <p className="text-muted">
              Enjoy the flexibility to modify your records at any point in the process. 

              </p>
            </div>
          </Col>

          <Col md={4} className="mb-4">
            <div className="more-feature-card">
              <img src={Component05} alt="Power of AI Icon" className="mb-3" />
              <h5 className="fw-bold">AI-Driven System</h5>
              <p className="text-muted">
              A smart, AI-powered system that makes complaint filing fast and simple.
              </p>
            </div>
          </Col>

          <Col md={4} className="mb-4">
            <div className="more-feature-card">
              <img src={Component06} alt="Complaint Data Analysis Icon" className="mb-3" />
              <h5 className="fw-bold">User-Friendly Navigation</h5>
              <p className="text-muted">
              Seamlessly navigate through the platform with an intuitive interface and clear step-by-step instructions.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
      <div className="container mb-5">
        <div className="divider"></div>
      </div>

  
   {/* Features Section */}
   <section >
        <Container>
          <Row className="mb-5">
            {/* Left Column for Heading */}
            <Col md={12} className="features-text">
              <h6 className="features-heading-faq">FAQs (Frequently Asked Questions)</h6>
            </Col>

       
                  <Col lg={10} className="mt-5 mb-5" style={{margin : 'auto'}}>
                  
                    <div className="accordion" id="accordion">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !col1 }
                            )}
                            type="button"
                            onClick={t_col1}
                            style={{ cursor: "pointer" }}
                          >
                            Accordion Item #1
                          </button>
                        </h2>

                        <Collapse isOpen={col1} className="accordion-collapse">
                          <div className="accordion-body">
                            <div className="text-muted">
                              <strong className="text-dark">
                                This is the first item&apos;s accordion body.
                              </strong>{" "}
                              It is hidden by default, until the collapse plugin
                              adds the appropriate classes that we use to style
                              each element. These classes control the overall
                              appearance, as well as the showing and hiding via
                              CSS transitions. You can modify any of this with
                              custom CSS or overriding our default variables.
                              It&apos;s also worth noting that just about any HTML
                              can go within the <code>.accordion-body</code>,
                              though the transition does limit overflow.
                            </div>
                          </div>
                        </Collapse>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !col2 }
                            )}
                            type="button"
                            onClick={t_col2}
                            style={{ cursor: "pointer" }}
                          >
                            Accordion Item #2
                          </button>
                        </h2>

                        <Collapse isOpen={col2} className="accordion-collapse">
                          <div className="accordion-body">
                            <div className="text-muted">
                              <strong className="text-dark">
                                This is the second item&apos;s accordion body.
                              </strong>{" "}
                              It is hidden by default, until the collapse plugin
                              adds the appropriate classes that we use to style
                              each element. These classes control the overall
                              appearance, as well as the showing and hiding via
                              CSS transitions. You can modify any of this with
                              custom CSS or overriding our default variables.
                              It&apos;s also worth noting that just about any HTML
                              can go within the <code>.accordion-body</code>,
                              though the transition does limit overflow.
                            </div>
                          </div>
                        </Collapse>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !col3 }
                            )}
                            type="button"
                            onClick={t_col3}
                            style={{ cursor: "pointer" }}
                          >
                            Accordion Item #3
                          </button>
                        </h2>
                        <Collapse isOpen={col3} className="accordion-collapse">
                          <div className="accordion-body">
                            <div className="text-muted">
                              <strong className="text-dark">
                                This is the third item&apos;s accordion body.
                              </strong>{" "}
                              It is hidden by default, until the collapse plugin
                              adds the appropriate classes that we use to style
                              each element. These classes control the overall
                              appearance, as well as the showing and hiding via
                              CSS transitions. You can modify any of this with
                              custom CSS or overriding our default variables.
                              It&apos;s also worth noting that just about any HTML
                              can go within the <code>.accordion-body</code>,
                              though the transition does limit overflow.
                            </div>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </Col>
                 
          

          
          </Row>

        </Container>
      </section>

      <div className="container">
        <div className="divider"></div>
      </div>

      {/* Footer Section */}
      <footer className="footer-section py-5">
      <Container>
        <Row className="justify-content-between footer-style">
          {/* Left Side: Logo */}
          <Col md={12} className="align-center text-md-left mb-4 mb-md-0">
            <img
              src="https://smarteeva.com/wp-content/uploads/2019/05/Logo-Smarteeva-sm.svg"
              alt="Smarteeva Logo"
              className="footer-logo"
            />
             <p className="features-description" style={{color : '#369bff'}}>
          Simplified post-market surveillance
          </p>
          </Col>
         

          {/* Right Side: Links */}
          {/* <Col md={8}>
            <Row>
              <Col md={6} className="text-left text-md-left mb-4 mb-md-0">
                <h5 className="footer-heading">Product</h5>
                <ul className="list-unstyled footer-links">
                  <li><a href="#features">Features</a></li>
                  <li><a href="#features">Documentation 01</a></li>
                  <li><a href="#documentation">Documentation 02</a></li>
                </ul>
              </Col>

              <Col md={6} className="text-left text-md-left">
                <h5 className="footer-heading">Company</h5>
                <ul className="list-unstyled footer-links">
                  <li><a href="#about">About</a></li>
                  <li><a href="#terms">Terms</a></li>
                  <li><a href="#privacy">Privacy Policy</a></li>
                  <li><a href="#careers">Careers</a></li>
                </ul>
              </Col>
            </Row>
          </Col> */}
        </Row>
      </Container>
    </footer>

    <Loader isLoading={isLoading} /> 

    </div>
  );
}

export default LoginLanding;
