import React, { useState, useEffect } from 'react';
import { Card, CardHeader, Button, CardBody, Collapse, Input, Col, InputGroup, Label } from 'reactstrap';
import Select from 'react-select';
import Flatpickr from "react-flatpickr";
import Dropzone from "react-dropzone";
import { useSelector, useDispatch } from "react-redux";
import { addEventAnswer, addEventAnswerChecklist, addEventAnswerRegular, saveMailerAnswers } from 'store/actions';
import moment from 'moment';
import Notiflix from 'notiflix';
import { image_upload } from 'helpers/dataConfig';


const RegularBranching = ({ questions, eventData ,ansData, type}) => {

  const dispatch = useDispatch();


  const [activeIndex, setActiveIndex] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(questions[0]);
  const [commentsVisible, setCommentsVisible] = useState(false);
  const [currentChoice, setCurrentChoice] = useState(null);
  const [childQuestions, setChildQuestions] = useState(null);
  const [viewQuestionData, setViewQuestionData] = useState([]);

  // const toggle = index => {
  //     setActiveIndex(activeIndex === index ? null : index);
  // };
  const [answerText, setAnswerText] = useState('');
  const [commentsText, setCommentsText] = useState('');
  const [answerQuestion, setAnswerQuestion] = useState('');

  const toggle = (index, choice) => {
    // setActiveIndex(activeIndex === index ? null : index);
    // setCurrentQuestion(questions[index])
    // console.log(index == -1)

    if (questions.length > index) {
      setCurrentQuestion(null)
      setCurrentChoice(null)
      setCommentsVisible(false)
    } else {
      setCurrentChoice(null)
      setCommentsVisible(false)
      setCurrentQuestion(questions[index])
      // currentChoice(choice)
      // commentsVisible(true)

      // showComments(questions[index],choice)

    }
  };

  const showComments = (ques, choice) => {
    if (choice.display_comments) {
      setCurrentChoice(choice)
      setCommentsVisible(true)
    } else {
      setCurrentChoice(null)
      setCommentsVisible(false)
      const nextQuestionIndex = questions.findIndex(q => q.question_id === currentQuestion.id);
      toggle(nextQuestionIndex + 1, choice);
    }

  }

  useEffect(() => {
    // console.log(ansData)
    setViewQuestionData(questions)
    setCurrentQuestion(questions[0])
    setChildQuestions(questions.filter(q => q.parent_content_item === questions[0].question_id))
    //   setChildCount(questions.filter(q => q.parent_content_item === questions[0].question_id).length)
  }, [questions])



  useEffect(() => {

    if (currentQuestion != null) {
      setChildQuestions(questions.filter(q => q.parent_content_item === currentQuestion.question_id))
    }
    //   setChildCount(questions.filter(q => q.parent_content_item === questions[0].question_id).length)
  }, [currentQuestion])

  useEffect(() => {

  }, [answerText, currentChoice])



  // Filter questions to get only those with no parent (top-level branching questions)
  const topLevelQuestions = questions.filter(q => q.content_item_type === "Branching" && !q.parent_content_item);

  // Function to render different question types
  const renderQuestionType = (question) => {
    switch (question.content_item_type) {
      case "Form":
        return <div style={{ padding: 20, display: 'grid' }}>
          {question.choice_data.map((choice, ch_index) => (
            <div key={ch_index}>
              {renderFormType(choice, question)}

            </div>

          ))}
          <button style={{ margin: 'auto' }} className='btn saveButton' onClick={() => {
            const nextQuestionIndex = questions.findIndex(q => q.question_id === currentChoice.next_question);
            // toggle(nextQuestionIndex);
            //   if(nextQuestionIndex == -1){
            //     setCurrentQuestion(null)
            //   }else{
            //     dispatch(addEventAnswerChecklist({currentQuestion, eventid : eventData.EVENT_ID}))
            //     setCurrentQuestion(questions[index])

            //   }
            // dispatch(addEventAnswerChecklist({currentQuestion, eventid : eventData.EVENT_ID}))

            let update_child = [...childQuestions];

            // Check if all ans_item_text in choice_data are empty
            if (question.choice_data.every(q => q.ans_item_text != '')) {
              // Find the index of the question in childQuestions
              const questionIndex = childQuestions.findIndex(q => q.question_id === question.question_id);

              // If the question is found, update its answer_text to 'Form Done'
              // console.log(questionIndex)
              if (questionIndex !== -1) {
                update_child[questionIndex] = {
                  ...update_child[questionIndex],
                  answer_text: 'Form Done'
                };
              }
            }
            setChildQuestions(update_child)

            let empty_ques = question.choice_data.filter(q => q.ans_item_text == '')


            if (empty_ques.length <= 0) {
              let child_ques = questions.filter(q => q.parent_content_item === currentQuestion.question_id)
              let child_ans = questions.filter(q => q.parent_content_item === currentChoice.question_id && q.answer_text == '')

              if (nextQuestionIndex == -1) {

                // console.log(child_ans)

                if (child_ques.length > 0) {
                  // setCurrentQuestion(updatedQuestion)
                  let child_new = childQuestions[childQuestions.findIndex(q => q.question_id === question.question_id)]
                  dispatch(addEventAnswerRegular({ ...child_new, eventid: eventData.EVENT_ID }))  


                  // if(child_ans.length <= 0){

                  setCurrentQuestion(null)
                  setChildQuestions(null)
                  // }
                } else {
                  setCurrentQuestion(null)
                  setChildQuestions(null)
                }

              } else {
                if (child_ques.length > 0) {
                  // setCurrentQuestion(updatedQuestion)
                  let child_new = childQuestions[childQuestions.findIndex(q => q.question_id === question.question_id)]
                  dispatch(addEventAnswerRegular({ ...child_new, eventid: eventData.EVENT_ID })) 

                  if (child_ans.length <= 0) {
                    setCurrentQuestion(questions[nextQuestionIndex])
                  }
                } else {
                  setCurrentQuestion(questions[nextQuestionIndex])
                }
              }
              // if(child_ques.length > 0){
              //     // setCurrentQuestion(updatedQuestion)
              //     let child_new= childQuestions[childQuestions.findIndex(q => q.question_id === question.question_id)]
              // dispatch(addEventAnswerRegular({ ...child_new, eventid : eventData.EVENT_ID}))

              // if(child_ans.length <= 0){
              //     setCurrentQuestion(questions[nextQuestionIndex])
              // }
              // }else{

              //     if(nextQuestionIndex == -1){
              //         //   setFinalAnswer(currentQuestion)
              //         setCurrentQuestion(null)
              //         setChildQuestions(null)
              //         // console.log(updatedQuestion)
              //         }else{
              //         setCurrentQuestion(questions[nextQuestionIndex])
              //         let child_new= childQuestions[childQuestions.findIndex(q => q.question_id === question.question_id)]
              //         dispatch(addEventAnswerRegular({...child_new, eventid : eventData.EVENT_ID}))
              //         }
              // }
            } else {
              Notiflix.Notify.failure('Please fill all details')
            }

          }}>
            <i className="bx bx-save"></i> Save

          </button>
        </div>; // Simplified
      case "Choices Multiple":
        return <div style={{ padding: 20 }}>
          {MultipleTextView(question)}

          <button style={{ margin: 'auto' }} className='btn saveButton' onClick={() => {

            setAnswerQuestion(question)
            //   setCurrentChoice(choice)
            if (!currentChoice.display_comments) {
              updateChildQuestionAnswer(question, currentChoice, answerText)
            }
          }}>
            <i className="bx bx-save"></i> Save

          </button>
        </div>
      case "Content Piece":
        return <div dangerouslySetInnerHTML={{ __html: question.question_sub_text }} />;
      // case "Image Attachment":
      case "Image Attachment":
        return <div>
          {question.choice_data.map((choice, ind) => (
            <Dropzone key={ind}
              onDrop={acceptedFiles => {
                // handleAcceptedFiles(acceptedFiles)
                //  console.log(acceptedFiles)
                const data = new FormData();
                data.append('file', acceptedFiles[0]);

                try {
                  const response = axios.post(image_upload, data, {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  });
                  //    console.log('Response:', response.data);
                  updateChildQuestionAnswer(question, choice, response.fileurl)
                } catch (error) {
                  console.error('Error uploading file:', error);
                }
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone">
                  <div
                    className="dz-message needsclick mt-2"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <div className="mb-3">
                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                    </div>
                    <h4>Drop images here or click to upload.</h4>
                  </div>
                </div>
              )}
            </Dropzone>
          ))}
        </div>
      case "Choices":
        return <div style={{ padding: 20 }}>
          {SingleSelectTextView(question)}

        </div>
      default:
        return null;
    }
  };



   // Function to render different question types
   const renderAnswerType = (question) => {
    // console.log(question)
    switch (question.content_item_type) {
      case "Branching":
        return  <div> {question.answer_text}</div>
      case "Form":
        return <div style={{ padding: 20, display: 'grid' }}>
          {question.choice_data.map((choice, ch_index) => (
            <div key={ch_index}>
              <h6>{choice.choice_text}</h6>
              <p>{choice.ans_item_text}</p>
            </div>

          ))}
          {/* <button style={{ margin: 'auto' }} className='btn saveButton' onClick={() => {
            const nextQuestionIndex = questions.findIndex(q => q.question_id === currentChoice.next_question);
            // toggle(nextQuestionIndex);
            //   if(nextQuestionIndex == -1){
            //     setCurrentQuestion(null)
            //   }else{
            //     dispatch(addEventAnswerChecklist({currentQuestion, eventid : eventData.EVENT_ID}))
            //     setCurrentQuestion(questions[index])

            //   }
            // dispatch(addEventAnswerChecklist({currentQuestion, eventid : eventData.EVENT_ID}))

            let update_child = [...childQuestions];

            // Check if all ans_item_text in choice_data are empty
            if (question.choice_data.every(q => q.ans_item_text != '')) {
              // Find the index of the question in childQuestions
              const questionIndex = childQuestions.findIndex(q => q.question_id === question.question_id);

              // If the question is found, update its answer_text to 'Form Done'
              // console.log(questionIndex)
              if (questionIndex !== -1) {
                update_child[questionIndex] = {
                  ...update_child[questionIndex],
                  answer_text: 'Form Done'
                };
              }
            }
            setChildQuestions(update_child)

            let empty_ques = question.choice_data.filter(q => q.ans_item_text == '')


            if (empty_ques.length <= 0) {
              let child_ques = questions.filter(q => q.parent_content_item === currentQuestion.question_id)
              let child_ans = questions.filter(q => q.parent_content_item === currentChoice.question_id && q.answer_text == '')

              if (nextQuestionIndex == -1) {

                // console.log(child_ans)

                if (child_ques.length > 0) {
                  // setCurrentQuestion(updatedQuestion)
                  let child_new = childQuestions[childQuestions.findIndex(q => q.question_id === question.question_id)]
                  dispatch(addEventAnswerRegular({ ...child_new, eventid: eventData.EVENT_ID }))  


                  // if(child_ans.length <= 0){

                  setCurrentQuestion(null)
                  setChildQuestions(null)
                  // }
                } else {
                  setCurrentQuestion(null)
                  setChildQuestions(null)
                }

              } else {
                if (child_ques.length > 0) {
                  // setCurrentQuestion(updatedQuestion)
                  let child_new = childQuestions[childQuestions.findIndex(q => q.question_id === question.question_id)]
                  dispatch(addEventAnswerRegular({ ...child_new, eventid: eventData.EVENT_ID })) 

                  if (child_ans.length <= 0) {
                    setCurrentQuestion(questions[nextQuestionIndex])
                  }
                } else {
                  setCurrentQuestion(questions[nextQuestionIndex])
                }
              }
              // if(child_ques.length > 0){
              //     // setCurrentQuestion(updatedQuestion)
              //     let child_new= childQuestions[childQuestions.findIndex(q => q.question_id === question.question_id)]
              // dispatch(addEventAnswerRegular({ ...child_new, eventid : eventData.EVENT_ID}))

              // if(child_ans.length <= 0){
              //     setCurrentQuestion(questions[nextQuestionIndex])
              // }
              // }else{

              //     if(nextQuestionIndex == -1){
              //         //   setFinalAnswer(currentQuestion)
              //         setCurrentQuestion(null)
              //         setChildQuestions(null)
              //         // console.log(updatedQuestion)
              //         }else{
              //         setCurrentQuestion(questions[nextQuestionIndex])
              //         let child_new= childQuestions[childQuestions.findIndex(q => q.question_id === question.question_id)]
              //         dispatch(addEventAnswerRegular({...child_new, eventid : eventData.EVENT_ID}))
              //         }
              // }
            } else {
              Notiflix.Notify.failure('Please fill all details')
            }

          }}>
            <i className="bx bx-save"></i> Save

          </button> */}
        </div>; // Simplified
      case "Choices Multiple":
        return <div style={{ padding: 20 }}>
          
           <div> {question.answer_text}</div>

          {/* <button style={{ margin: 'auto' }} className='btn saveButton' onClick={() => {

            setAnswerQuestion(question)
            //   setCurrentChoice(choice)
            if (!currentChoice.display_comments) {
              updateChildQuestionAnswer(question, currentChoice, answerText)
            }
          }}>
            <i className="bx bx-save"></i> Save

          </button> */}
        </div>
      case "Content Piece":
        return <div dangerouslySetInnerHTML={{ __html: question.question_sub_text }} />;
      // case "Image Attachment":
      case "Image Attachment":
        return <div>
          {/* {question.choice_data.map((choice, ind) => ( */}
         
            <div >
            <img src={question.answer_text} />
            </div>
     
        </div>
      case "Choices":
        return <div style={{ padding: 20 }}>
          {/* {SingleSelectTextView(question)} */}
            {question.answer_text}
        </div>
      default:
        return null;
    }
  };


  //  // Function to render different form types
  //  const renderFormType = (choice) => {
  //     switch (choice.field_type) {
  //         case "Date":
  //             return <div><Label>{choice.choice_text}</Label>{DateTextView()}   <hr
  //             style={{
  //                 color: '#dcdcdc',
  //                 backgroundColor: '#dcdcdc',
  //                 height: 1
  //             }}
  //         /></div>;
  //         case "Picklist":
  //             return <div><Label>{choice.choice_text}</Label>{DropdownTextView(choice.picklist_values.split(','))}
  //             <hr
  //             style={{
  //                 color: '#dcdcdc',
  //                 backgroundColor: '#dcdcdc',
  //                 height: 1
  //             }}
  //         />
  //             </div>;
  //         case "Text":
  //             return <div><Label>{choice.choice_text}</Label>{ShortTextView()}
  //             <hr
  //             style={{
  //                 color: '#dcdcdc',
  //                 backgroundColor: '#dcdcdc',
  //                 height: 1
  //             }}
  //         />
  //             </div>;
  //         case "Long Text":
  //             return <div><Label>{choice.choice_text}</Label>{LongTextView()}
  //             <hr
  //             style={{
  //                 color: '#dcdcdc',
  //                 backgroundColor: '#dcdcdc',
  //                 height: 1
  //             }}
  //         />
  //             </div>;
  //         case "Number":
  //             return <div><Label>{choice.choice_text}</Label>{NumberTextView()}
  //             <hr
  //             style={{
  //                 color: '#dcdcdc',
  //                 backgroundColor: '#dcdcdc',
  //                 height: 1
  //             }}
  //         />
  //             </div>;
  //         default:
  //             return null;
  //     }
  // };


  // Function to render different form types
  const renderFormType = (choice, question) => {
    switch (choice.field_type) {
      case "Date":
        return <div className='row'>
          <div className={choice.display_comments ? 'col-lg-12' : 'col-lg-12'}><Label>{choice.choice_text}</Label>{DateTextView(question, choice)}
            <div className='col-lg-12'>
              <div className="input-wrapper mb-5" style={{ display: choice.display_comments ? '' : 'none' }}>
                <Label className={choice.comments_required ? "form-label required" : "form-label"} style={{ marginBottom: 0 }}>Add Comments</Label>
                <Input
                  name="key"
                  type="textarea"
                  rows={5}
                  value={choice.ans_item_comments}
                  onChange={(text) => {
                    updateQuestionItemComments(question, choice, text.target.value)
                  }}
                  placeholder="" />
              </div>
            </div>
            <hr
              style={{
                color: '#dcdcdc',
                backgroundColor: '#dcdcdc',
                height: 1
              }}
            /></div>

        </div>;
      case "Picklist":
        return <div className='row'><div className='col-lg-12'><Label>{choice.choice_text}</Label>{DropdownTextView(question, choice)}

          <div className='col-lg-12'>
            <div className="input-wrapper mb-5" style={{ display: choice.display_comments ? '' : 'none' }}>
              <Label className={choice.comments_required ? "form-label required" : "form-label"} style={{ marginBottom: 0 }}>Add Comments</Label>
              <Input
                name="key"
                type="textarea"
                rows={5}
                onChange={(text) => {
                  updateQuestionItemComments(question, choice, text.target.value)
                }}
                placeholder="" />
            </div>
          </div>
          <hr
            style={{
              color: '#dcdcdc',
              backgroundColor: '#dcdcdc',
              height: 1
            }}
          />
        </div>
        </div>;
      case "Text":
        return <div className='row'><div className={choice.display_comments ? 'col-lg-12' : 'col-lg-12'}><Label className="form-label required">{choice.choice_text}</Label>{ShortTextView(question, choice)}
        </div>
          <div className='col-lg-12'>
            <div className="input-wrapper mb-5" style={{ display: choice.display_comments ? '' : 'none' }}>
              <Label className={choice.comments_required ? "form-label required" : "form-label"}>Add Comments</Label>
              <Input
                name="key"
                type="textarea"
                rows={3}
                placeholder="" />
            </div>
          </div>
          <hr
            style={{
              color: '#dcdcdc',
              backgroundColor: '#dcdcdc',
              height: 1
            }}
          />


        </div>;
      case "Long Text":
        return <div className='row'><div className='col-lg-12'><Label>{choice.choice_text}</Label>{LongTextView(question, choice)}

          <div className='col-lg-12'>
            <div className="input-wrapper mb-5" style={{ display: choice.display_comments ? '' : 'none' }}>
              <Label className={choice.comments_required ? "form-label required" : "form-label"} style={{ marginBottom: 0 }}>Add Comments</Label>
              <Input
                name="key"
                type="textarea"
                rows={5}
                onChange={(text) => {
                  updateQuestionItemComments(question, choice, text.target.value)
                }}
                placeholder="" />
            </div>
          </div>
          <hr
            style={{
              color: '#dcdcdc',
              backgroundColor: '#dcdcdc',
              height: 1
            }}
          />
        </div>
        </div>;
      case "Number":
        return <div className='row'><div className='col-lg-6'><Label>{choice.choice_text}</Label>{NumberTextView(question, choice)}

          <div className='col-lg-12'>
            <div className="input-wrapper mb-5" style={{ display: choice.display_comments ? '' : 'none' }}>
              <Label className={choice.comments_required ? "form-label required" : "form-label"} style={{ marginBottom: 0 }}>Add Comments</Label>
              <Input
                name="key"
                type="textarea"
                rows={5}
                onChange={(text) => {
                  updateQuestionItemComments(question, choice, text.target.value)
                }}
                placeholder="" />
            </div>
          </div>
          <hr
            style={{
              color: '#dcdcdc',
              backgroundColor: '#dcdcdc',
              height: 1
            }}
          />
        </div>
        </div>;
      default:
        return null;
    }
  };


  const ShortTextView = (question, choice) => {
    return (
      <Col lg='12'>
        <div className=" mb-3">
          <Input
            //   disabled 
            name="key"
            type="textarea"
            rows={3}
            placeholder=""
            value={choice.ans_item_text}
            onChange={(e) => updateQuestionItemAnswer(question, choice, e.target.value)
            }
          />
        </div>
      </Col>
    )
  }


  const LongTextView = (question, choice) => {
    return (
      <Col lg='12'>
        <div className="mb-3">
          <Input
            //   disabled
            name="key"
            type="textarea"
            rows={5}
            placeholder=""
            value={choice.ans_item_text}
            onChange={(e) => updateQuestionItemAnswer(question, choice, e.target.value)
            }
          />
        </div>
      </Col>
    )
  }


  const NumberTextView = (question, choice) => {
    return (
      <Col lg='12'>
        <div className="mb-3">
          <InputGroup>
            <div
              className="input-group-append"
              onClick={() => {
                if (counter > 0) {
                  setCounter(counter - 1);
                  updateQuestionItemAnswer(question, choice, counter - 1)
                }
              }}
            >
              <Button type="button" color="primary">
                <i className="mdi mdi-minus" />
              </Button>
            </div>
            <Input
              style={{
                borderRight: 0,
                marginRight: -5,
                borderLeft: 0,
                marginLeft: -5,
                background: 'transparent',
                textAlign: 'center'
              }}

              type="number"
              // className="form-control"
              value={counter}
              onChange={(e) => {
                // if(e.target.value == ''){
                //   setCounter(0)
                // }
                setCounter(parseInt(e.target.value))
                updateQuestionItemAnswer(question, choice, parseInt(e.target.value))
                // }
              }}
            // placeholder="number"
            // readOnly

            />
            <div
              className="input-group-append"
              onClick={() => {

                setCounter(counter + 1);
                updateQuestionItemAnswer(question, choice, counter + 1)

              }}
            >
              <Button type="button" color="primary">
                <i className="mdi mdi-plus" />
              </Button>
            </div>
          </InputGroup>
        </div>
      </Col>
    )
  }


  const DateTextView = (question, choice) => {
    return (
      <Col lg='12'>
        <div className="mb-3">
          <Flatpickr

            className="form-control d-block"
            placeholder="Pick a date"
            options={{
              altInput: true,
              dateFormat: "d-m-y"
            }}
            value={Date.parse(choice.ans_item_text)}
            onChange={(e) => updateQuestionItemAnswer(question, choice, moment(new Date(e)).format('MM/DD/YYYY'))
            }
          />
        </div>
      </Col>
    )
  }


  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'white',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'white' : 'white', // Change background color when selected
      color: state.isSelected ? 'black' : 'black', // Change text color when selected
    }),
  };

  const DropdownTextView = (question, choice) => {

    return (
      <Col lg='12'>
        <div className="mb-3">


          <Select
            name={''}
            isClearable
            isSearchable
            options={choice.picklist_values.split(',').map((option, index) => ({
              label: option,
              value: option, // or any unique identifier for the value
            }))}
            value={{ label: choice.ans_item_text, value: choice.ans_item_text }}
            onChange={(e) => updateQuestionItemAnswer(question, choice, e.value)
            }
            styles={customStyles}

          />

        </div>
      </Col>
    )
  }

  const MultipleTextView = (ques) => {
    return (
      <Col lg='12'>
        <div className="mb-3" style={{ paddingLeft: 15, paddingRight: 15 }}>

          {ques.choice_data.map((choice, index) => (
            <div className="form-check form-checkbox-outline form-check-primary mb-4 col-lg-12" key={index}>
              <input
                style={{ color: '#232D42' }}
                type="checkbox"
                className="form-check-input"
                id="customCheck-outlinecolor1"
                checked={answerText.split(',').includes(choice.choice_text)}
                onClick={() => {
                  setAnswerText((prevAnswerText) => {
                    let selectedChoices = prevAnswerText.split(',').filter(Boolean);
                    if (selectedChoices.includes(choice.choice_text)) {
                      selectedChoices = selectedChoices.filter(choice => choice !== choice.choice_text);
                    } else {
                      selectedChoices.push(choice.choice_text);
                    }
                    return selectedChoices.join(',');
                  });

                  setAnswerQuestion(ques)
                  setCurrentChoice(choice)
                  // if(!choice.display_comments){
                  //   updateQuestionAnswer(ques)
                  // }
                }}
              />
              <label
                style={{ color: '#232D42' }}
                className="form-check-label"
                htmlFor="customCheck-outlinecolor1"
              >
                {choice.choice_text}
              </label>
            </div>
          ))}


        </div>
      </Col>
    )
  }

  const SingleSelectTextView = (ques) => {
    return (
      <Col lg='12'>
        <div className="mb-3" style={{ paddingLeft: 15, paddingRight: 15 }}>

          {ques.choice_data.map((choice, index) => (
            <div className="form-check form-checkbox-outline form-check-primary mb-4 col-lg-12" key={index}>
              <input
                style={{ color: '#232D42' }}
                type="radio"
                className="form-check-input"
                id="customCheck-outlinecolor1"
                checked={answerText === choice.choice_text}
                onChange={() => {
                  setAnswerText(choice.choice_text);
                  setAnswerQuestion(ques)
                  setCurrentChoice(choice)
                  if (!choice.display_comments) {
                    updateChildQuestionAnswer(ques, choice, choice.choice_text)
                  }
                }}
              />
              <label
                style={{ color: '#232D42' }}
                className="form-check-label"
                htmlFor="customCheck-outlinecolor1"
              >
                {choice.choice_text}
              </label>
            </div>
          ))}


        </div>
      </Col>
    )
  }


  // Function to update the answer for a specific question
  const updateQuestionAnswer = (question, choice, ans_text) => {

 
    let updatedIndex = 0
    // console.log(choice)
    const nextQuestionIndex = questions.findIndex(q => q.question_id === choice.next_question);
    const updatedQuestion = { ...currentQuestion, answer_text: ans_text, ans_comments: commentsText };

    // setAnswerText('')
    // setCommentsText('')
    // setAnswerQuestion('')
    // setCurrentChoice('')

    // setCurrentQuestion(nextQuestionIndex);
    let child_ques = questions.filter(q => q.parent_content_item === choice.question_id)
    let child_ans = questions.filter(q => q.parent_content_item === choice.question_id && q.answer_text == '')
    dispatch(addEventAnswer({ ...updatedQuestion, eventid: eventData.EVENT_ID }))

    if (nextQuestionIndex == -1) {

      let quesMainData = [...viewQuestionData]      
      quesMainData[quesMainData.findIndex(val => val.question_id == question.question_id)] = updatedQuestion
      setViewQuestionData(quesMainData)
      dispatch(saveMailerAnswers(quesMainData))

      if (child_ques.length > 0) {

        setCurrentQuestion(updatedQuestion)
    

        if (child_ans.length <= 0) {
          setCurrentQuestion(null)
          setChildQuestions(null)
        }

      } else {
        setCurrentQuestion(null)
        setChildQuestions(null)
      }
    }
    else {

      let quesMainData = [...viewQuestionData]
      quesMainData[quesMainData.findIndex(val => val.question_id == question.question_id)] = updatedQuestion
      setViewQuestionData(quesMainData)
      dispatch(saveMailerAnswers(quesMainData))

      if (child_ques.length > 0) {

        setCurrentQuestion(updatedQuestion)


        if (child_ans.length <= 0) {
          setCurrentQuestion(questions[nextQuestionIndex])
        }
      } else {
        setCurrentQuestion(questions[nextQuestionIndex])

      }
    }
    // let child_ques = questions.filter(q => q.parent_content_item === choice.question_id)
    // let child_ans = questions.filter(q => q.parent_content_item === choice.question_id && q.answer_text == '')
    // if(child_ques.length > 0){
    //     setCurrentQuestion(updatedQuestion)
    //     dispatch(addEventAnswer({...updatedQuestion, eventid : eventData.EVENT_ID})) 

    //     if(child_ans.length <= 0){
    //         setCurrentQuestion(questions[nextQuestionIndex])
    //     }

    // }else{
    //     dispatch(addEventAnswer({...updatedQuestion, eventid : eventData.EVENT_ID}))

    //     if(nextQuestionIndex == -1){
    //         //   setFinalAnswer(currentQuestion)
    //           setCurrentQuestion(null)
    //           setChildQuestions(null)
    //         // console.log(updatedQuestion)
    //         }else{
    //         setCurrentQuestion(questions[nextQuestionIndex])
    //         }
    // }


  };


  // Function to update the answer for a specific question
  const updateChildQuestionAnswer = (question, choice, ans_text) => {
    // Find the index of the child question to update
    const child_id = childQuestions.findIndex(q => q.question_id === question.question_id);
    const nextQuestionIndex = questions.findIndex(q => q.question_id === choice.next_question);

    // Update the specific child question with the provided answer and comments
    childQuestions[child_id] = { ...childQuestions[child_id], answer_text: ans_text, ans_comments: commentsText };
    const updatedQuestion = [...childQuestions];

    // Identify child questions related to the current choice
    const child_ques = questions.filter(q => q.parent_content_item === choice.question_id);
    const child_ans = questions.filter(q => q.parent_content_item === choice.question_id && q.answer_text === '');

    if (nextQuestionIndex === -1) {

      setChildQuestions(updatedQuestion);

      let quesMainData = [...viewQuestionData]
        quesMainData[quesMainData.findIndex(val => val.question_id == question.question_id)] = updatedQuestion
        setViewQuestionData(quesMainData)
        dispatch(saveMailerAnswers(quesMainData))

      dispatch(addEventAnswer({ ...childQuestions[child_id], eventid: eventData.EVENT_ID })); 

      if (child_ques.length > 0) {
        // Update state with the modified child questions
        // Proceed to the next main question if all child questions are answered
        if (child_ans.length <= 0) {
          //   setCurrentQuestion(questions[nextQuestionIndex]);
          setCurrentQuestion(null); // No more questions
          setChildQuestions(null)
        }
      } else {
        setCurrentQuestion(null); // No more questions
        setChildQuestions(null)
        // setCurrentQuestion(questions[nextQuestionIndex]);
      }

    }
    else {

      let quesMainData = [...viewQuestionData]
        quesMainData[quesMainData.findIndex(val => val.question_id == question.question_id)] = updatedQuestion
        setViewQuestionData(quesMainData)
        dispatch(saveMailerAnswers(quesMainData))

      setChildQuestions(updatedQuestion);
      dispatch(addEventAnswer({ ...childQuestions[child_id], eventid: eventData.EVENT_ID }));  

      if (child_ques.length > 0) {
        // Update state with the modified child questions
        // Proceed to the next main question if all child questions are answered
        if (child_ans.length <= 0) {
          setCurrentQuestion(questions[nextQuestionIndex]);
        }
      } else {
        // setCurrentQuestion(null); // No more questions
        // setChildQuestions(null)
        setCurrentQuestion(questions[nextQuestionIndex]);
      }

    }
    // Handle child questions or move to the next question
    // if (child_ques.length > 0) {
    //   // Update state with the modified child questions
    //   setChildQuestions(updatedQuestion);
    //   dispatch(addEventAnswer({ ...childQuestions[child_id], eventid: eventData.EVENT_ID }));

    //   // Proceed to the next main question if all child questions are answered
    //   if (child_ans.length <= 0) {
    //     setCurrentQuestion(questions[nextQuestionIndex]);
    //   }
    // } else {
    //   // If there are no more child questions, handle the next main question
    //   if (nextQuestionIndex === -1) {
    //     setCurrentQuestion(null); // No more questions
    //     setChildQuestions(null)
    //   } else {
    //     dispatch(addEventAnswer({ ...childQuestions[child_id], eventid: eventData.EVENT_ID }));
    //     setCurrentQuestion(questions[nextQuestionIndex]);
    //   }
    // }

  };


  const updateQuestionItemAnswer = (question, choice, answerText) => {
    // console.log(question)
    const updatedQuestions = childQuestions.map((q) => {
      if (q.question_id == question.question_id) {
        const updatedChoices = q.choice_data.map((c) => {
          if (c.choice_id == choice.choice_id) {
            return { ...c, ans_item_text: answerText };
          }
          return c;
        });
        return { ...q, choice_data: updatedChoices };
      }
      return q;
    });
    // console.log(updatedQuestions)

    let quesMainData = [...viewQuestionData]
        quesMainData = updatedQuestions
        setViewQuestionData(quesMainData)
        dispatch(saveMailerAnswers(quesMainData))

    setChildQuestions(updatedQuestions)
  }

  // Function to update the answer for a specific question
  const updateQuestionItemComments = (question, choice, commentsText) => {

    const updatedQuestions = childQuestions.map((q) => {
      if (q.question_id == question.question_id) {
        const updatedChoices = q.choice_data.map((c) => {
          if (c.choice_id == choice.choice_id) {
            return { ...c, ans_item_comments: commentsText };
          }
          return c;
        });
        return { ...q, choice_data: updatedChoices };
      }
      return q;
    });
    // console.log(updatedQuestions)

    let quesMainData = [...viewQuestionData]
    quesMainData = updatedQuestions
        setViewQuestionData(quesMainData)
        dispatch(saveMailerAnswers(quesMainData))

    setChildQuestions(updatedQuestions)

  }

  return (
    <div>
      {/* {questions && topLevelQuestions.map((question, index) => ( */}
      {questions.length > 0 && currentQuestion != null && type == 'add' &&
        <Card key={currentQuestion.question_id}>
          <CardHeader className='cardheader002 card-header'>
            <h5 className='question-02'>
              {currentQuestion.question_text}
            </h5>
            <h6 style={{ marginLeft: 20 }}>{currentQuestion.question_sub_text != '<p>NA</p>' ? <div dangerouslySetInnerHTML={{ __html: currentQuestion.question_sub_text }} /> : ''}</h6>
          </CardHeader>
          <Collapse isOpen={true}>
            <CardBody>
              {currentQuestion.choice_data.map((choice) => (
                <div key={choice.choice_id}>
                  <button style={{ width: '100%', marginBottom : 20 }} className={
                    answerText == choice.choice_text ? 'btn btn-primary' : 'btn btn-outline-primary'} onClick={() => {
                      // const nextQuestionIndex = questions.findIndex(q => q.question_id === choice.next_question);
                      // showComments(currentQuestion, choice);

                      setAnswerText(choice.choice_text)
                      setAnswerQuestion(currentQuestion)
                      setCurrentChoice(choice)

                      if (!choice.display_comments) {
                        updateQuestionAnswer(currentQuestion, choice, choice.choice_text)
                      }

                    }}>
                    {choice.choice_text}
                  </button>
                </div>
              ))}

              {currentChoice && <div style={{ display: 'grid' }}><div className="input-wrapper mb-5" style={{ display: currentChoice.display_comments ? '' : 'none' }}>
                <Label className={currentChoice.comments_required ? "form-label required" : "form-label"} style={{ marginBottom: 0 }}>Add Comments</Label>
                <Input
                  name="key"
                  type="textarea"
                  rows={5}
                  onChange={(text) => {
                    setCommentsText(text.target.value)
                    //  updateQuestionComments(question, text)
                  }}
                  placeholder="" />
              </div>
                <button
                className="btn btn-primary"
                style={{ margin: 'auto', display: currentChoice.display_comments ? '' : 'none' }}  onClick={() => {
                  // const nextQuestionIndex = questions.findIndex(q => q.question_id === currentChoice.next_question);
                  // toggle(nextQuestionIndex);
                  updateQuestionAnswer(currentQuestion, currentChoice, answerText)

                }}>
                  <i className="bx bx-save"></i> Save

                </button>
              </div>
              }

              {/* Render sub-questions that have this question as their parent */}
              {childQuestions && childQuestions.map((subQuestion, index2) => (
                <div key={subQuestion.question_id} style={{ marginTop: '20px', display: currentQuestion.answer_text == '' ? 'none' : '',}}>

                  <Card key={currentQuestion.question_id}>
                    <CardHeader className='cardheader002 card-header' >
                      <h6 className="question-02">
                        {subQuestion.question_text}
                      </h6>
                      <h6 style={{ marginLeft: 20 }}>{currentQuestion.question_sub_text != '<p>NA</p>' ? <div dangerouslySetInnerHTML={{ __html: currentQuestion.question_sub_text }} /> : ''}</h6>
                    </CardHeader>
                    <Collapse isOpen>
                      {renderQuestionType(subQuestion)}


                    </Collapse>
                  </Card>
                </div>

              ))}
            </CardBody>
          </Collapse>
        </Card>
        // ))
      }
      {questions.length > 0 && (currentQuestion == null || childQuestions == null) ?
        <div>

          {/* <h5>Thank you!</h5> */}
          {/* <button className='btn btn-primary'
          onClick={(e)=>{
            e.preventDefault()
          }}
          >
            View Details
            </button> */}

{viewQuestionData.length > 0 && type == 'add' && 
viewQuestionData.map((ques,index)=>(
  <div key={index} style={{display : ques.answer_text == '' ? 'none' : ''}}>
  <h6 className='question-02'>
 Question {ques.sort_no}. {ques.question_text}
</h6>
<div style={{display: 'flex', alignItems: 'center'}}>
  <div style={{fontWeight : 450, marginRight : 10}}>Ans.</div> {renderAnswerType(ques)}
</div>
</div>
))
}


        </div>
        : ''}


{ansData.length > 0 && type == 'alter' &&
ansData.map((ques,index)=>(
  <div key={index} style={{display : ques.answer_text == '' ? 'none' : ''}}>
  <h6 className='question-02'>
 Question {ques.sort_no}. {ques.question_text}
</h6>
<div style={{display: 'flex', alignItems: 'center'}}>
  <div style={{fontWeight : 450, marginRight : 10}}>Ans.</div> {renderAnswerType(ques)}
</div>
</div>
))
}


    </div>
  );
};

export default RegularBranching;
