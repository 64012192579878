import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";

import classNames from "classnames";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
  Action,
  init,
  EmbedEvent,
  HostEvent,
  RuntimeFilterOp,
  AuthType
} from "@thoughtspot/visual-embed-sdk";
import { LiveboardEmbed, useEmbedRef } from "@thoughtspot/visual-embed-sdk/react";
import Loader from "components/Common/Loader";
import { authUser, ts_token_api_url } from "helpers/dataConfig";




const LandingPage = props => {
  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }));

  const [isLoading, setIsLoading] = useState(false);

  const [deviceCodes,setDeviceCodes] = useState([])
  const [patientCodes,setPatientCodes] = useState([])

  const [mdrYearData,setMdrYearData] = useState([])
  const [mdrMonthCounts,setMdrMonthCounts] = useState([])

  const [mdrEventCount,setMdrEventCount] = useState([])
  const [mdrYearlyCount,setMdrYearlyCount] = useState([])
  const [mdrMedicalSpecCount,setMdrMedicalSpecCount] = useState([])
  const [mdrMonthlyEventCount,setMdrMonthlyEventCount] = useState([])
  const [userData,setUserData] = useState(null)


  
  const [periodData, setPeriodData] = useState([]);
  const [periodType, setPeriodType] = useState("yearly");
  const [userCount, setUserCount] = useState([]);
  const [reports2, setReports2] = useState([])
  const [latestMdrSubmits,setLatestMdrSubmits] = useState([])
  const [mdrStats,setMdrStats] = useState([])
  const [mdrStatsYear,setMdrStatsYear] = useState([])



  

  const { loading } = useSelector(state => ({
    loading : state.Dashboard.loading
  }));


  useEffect(() => {
    // console.log(loading)
    setIsLoading(loading)
}, [loading]);

 

  //meta title
  document.title = "Landing Page | Smarteeva";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Complaints")}
            breadcrumbItem={props.t("Landing Page")}
          />


              
       
        </Container>
      </div>

<Loader isLoading={isLoading} /> 
    </React.Fragment>
  );
};

LandingPage.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(LandingPage);
