import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, socialLogin, googleSocialLogin,checkSocialLogin,
setLoadingIndicator
} from "../../store/actions";
import CarouselPage from "./CarouselPage";
// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.svg";
import { Auth } from "aws-amplify";
import moment from "moment";
import { authUser, sessionExpiration, sessionStorage } from "helpers/dataConfig";
import Loader from "components/Common/Loader";
import './landing.css';

const Login = props => {

  const [passwordShow, setPasswordShow] = useState(false);
  const [rememberPassword, setRememberPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);



  //meta title
  document.title = "Login | Smarteeva";

  const dispatch = useDispatch();

const [loginChecked, setLoginChecked] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: localStorage.getItem('rememberCreds')!= null ? JSON.parse(localStorage.getItem('rememberCreds')).email : '',
      password: localStorage.getItem('rememberCreds')!= null ? JSON.parse(localStorage.getItem('rememberCreds')).password : '',
      email: localStorage.getItem('rememberCreds')!= null ? JSON.parse(localStorage.getItem('rememberCreds')).email : '',
      password: localStorage.getItem('rememberCreds')!= null ? JSON.parse(localStorage.getItem('rememberCreds')).password : '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate, rememberPassword));
      // dispatch(loginUser(values, props.router.navigate, rememberPassword));
    }
  });

  const { error, loading } = useSelector(state => ({
    error: state.Login.error,
    loading : state.Login.loading
  }));

  // const signIn = type => {
  //   dispatch(socialLogin(type, props.router.navigate));
  // };

  //for facebook and google authentication
  const socialResponse = type => {
    signIn(type);
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  useEffect(()=>{
    // console.log(loading)
    let user = JSON.parse(localStorage.getItem(authUser))
    // console.log(localStorage.getItem(sessionStorage))
    if (user != null) {
      // props.router.navigate('/mdr-list')
      props.router.navigate('/dashboard')

    }else{
    dispatch(checkSocialLogin(props.router.navigate)) 
    }
  },[dispatch])


  const googleSignIn = () => {

    dispatch(googleSocialLogin());
    // try{
    //    await Auth.federatedSignIn({provider : 'Google'})
      
    // }catch(error){
    //   console.log(error)
    // }
  }


  useEffect(() => {
  
     setIsLoading(loading)
  
}, [loading]);

  return (
    <React.Fragment>
      <div>
        {/* <Container fluid className="p-0">
          <Row className="g-0">
            <Col xl={6}>
              <div className="auth-full-page-content p-md-5 p-4 centerJustCustom">
                <div className="w-100 maxWidthCustom">
                  <div className="d-flex flex-column h-100 centerJustCustom">
                    <div className="mb-2 mb-md-3 centerCustom">
                      <Link to="/dashboard" className="d-block auth-logo mb-3">
                        <img
                          src={logodark}
                          alt=""
                          height="70"
                          className="logo-dark-element"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="70"
                          className="logo-light-element"
                        />
                      </Link>
                    </div>
                    <div className="">
                      <div className="centerTextCustom">
                        <h5 className="welcome-text text-primary">Welcome Back !</h5>
                        <p className="text-muted">
                          Sign in to stay connected.
                        </p>
                      </div>

                      <div className="mt-4">
                        <Form className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >

{error ? <Alert color="danger">{error}</Alert> : null}

                          <div className="mb-3">
                            <Label className="form-label">Email Address</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email ? true : false
                              }
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <div className="float-end">
                              <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                            </div>
                            <Label className="form-label">Password</Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="password"
                                value={validation.values.password || ""}
                                type={passwordShow ? "text" : "password"}
                                placeholder="Enter Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.password && validation.errors.password ? true : false
                                }
                              />
                              <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                                <i className="mdi mdi-eye-outline"></i></button>
                            
                                {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                            </div>
                            
                          </div>

                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="auth-remember-check"
                              value={rememberPassword}
                              onClick={()=>{ setRememberPassword(!rememberPassword)}}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="auth-remember-check"
                            >
                              Remember me
                            </label>
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block "
                              type="submit"
                            >
                              Sign In
                            </button>
                          </div>

                        </Form>

                        <div className="mt-4 text-center">
                          

                            <ul className="list-inline">
                              
                            </ul>
                          </div>

                        <div className="mt-4 text-center">
                          <p>
                            Don&apos;t have an account?&nbsp;
                           
                              <p style={{marginTop : 10}}><Link
                              className="btn btn-primary btn-block "
                              type="submit"
                              to="/register"
                            >
                              Click here to Signup
                            </Link>
                            
                          </p>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <CarouselPage />

          </Row>
        </Container> */}
<Container className="paddingtop130">
            <Row>
              {/* Left Side: Branding Section */}
              <Col md={6} className="d-flex flex-column justify-content-center align-items-center signin-left">
                <img
                  src="https://smarteeva.com/wp-content/uploads/2019/05/Logo-Smarteeva-sm.svg"
                  alt="Smarteeva Logo"
                  className="mb-4"
                  style={{ marginRight: '10px' }}
                />
                <h1 className="welcome-title">Welcome back to Smarteeva</h1>
                <p className="welcome-text">
                No more tedious complaint filing! Enjoy the simplicity of Smarteeva’s one-step system.
                </p>
              </Col>

              {/* Right Side: Sign-In Form */}
              <Col md={6} className="d-flex justify-content-center align-items-center signin-right">


                <div className="signin-form-container">
                  <h3 className="signin-heading">Sign in</h3>
                  <p>
                    Don’t have an account? <a className="signup-link"
                    onClick={()=>{
                      props.setLogin()
                    }}
                    >Sign Up</a>
                  </p>

                  <Form className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >

{error ? <Alert color="danger">{error}</Alert> : null}

<div className="mb-3">
                            <Label className="form-label">Email Address</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email ? true : false
                              }
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                          </div>


                          <div className="mb-3">
                            <div className="float-end">
                              <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                            </div>
                            <Label className="form-label">Password</Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="password"
                                value={validation.values.password || ""}
                                type={passwordShow ? "text" : "password"}
                                placeholder="Enter Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.password && validation.errors.password ? true : false
                                }
                              />
                              <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                                <i className="mdi mdi-eye-outline"></i></button>
                            
                                {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                            </div>
                            
                          </div>

                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="auth-remember-check"
                              value={rememberPassword}
                              onClick={()=>{ setRememberPassword(!rememberPassword)}}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="auth-remember-check"
                            >
                              Remember me
                            </label>
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block "
                              type="submit"
                            >
                              Sign In
                            </button>
                          </div>


</Form>
                  {/* <Form>
                    <Form.Group controlId="formBasicEmail" className="mb-3">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control type="email" placeholder="Enter email" />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword" className="mb-3">
                      <Form.Label>Password</Form.Label>
                      <Form.Control type="password" placeholder="Password" />
                    </Form.Group>
                    <div className="d-flex justify-content-between">
                      <a href="#forgot" className="forgot-password-link">Forgot password?</a>
                    </div>
                    <Button variant="primary" type="submit" className="signin-btn mt-3">
                      Sign In
                    </Button>
                  </Form> */}
                </div>
              </Col>
            </Row>
          </Container>

      </div>


      {/* <Loader isLoading={isLoading} />  */}
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
